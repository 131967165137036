import {createReducer, combineReducers} from "@reduxjs/toolkit";

import {
    fetchSurveyStarted,
    fetchSurveySucceeded,
    fetchSurveyError,
    submitSurveyStarted,
    submitSurveySucceeded,
    submitSurveyError,
    skipSurveyStarted,
    skipSurveySucceeded,
    skipSurveyError,
    viewedSurveyStarted,
    viewedSurveySucceeded,
    viewedSurveyError,
} from "../actions/surveys";

const surveys = createReducer(
    {
        loading: false,
        data: [],
        error: undefined,
        surveyIsSubmitting: false,
        surveyResult: null,
        submitError: undefined,
    },
    {
        [fetchSurveyStarted]: (state) => {
            state.loading = true;
            state.error = undefined;
        },
        [fetchSurveySucceeded]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [fetchSurveyError]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [submitSurveyStarted]: (state) => {
            state.surveyIsSubmitting = true;
            state.submitError = undefined;
        },
        [submitSurveySucceeded]: (state, action) => {
            state.surveyIsSubmitting = false;
            state.surveyResult = action.payload;
        },
        [submitSurveyError]: (state, action) => {
            state.surveyIsSubmitting = false;
            state.submitError = action.payload;
        },
    }
);

const vote = createReducer(
    {
        isSubmitting: false,
        surveyResult: null,
        submitError: undefined,
    },
    {
        [submitSurveyStarted]: (state) => {
            state.isSubmitting = true;
            state.submitError = undefined;
        },
        [submitSurveySucceeded]: (state, action) => {
            state.isSubmitting = true;
            state.surveyResult = action.payload;
        },
        [submitSurveyError]: (state, action) => {
            state.isSubmitting = false;
            state.submitError = action.payload;
        }
    }
);

const viewed = createReducer(
    {
        isSubmitting: false,
        isViewed: false,
        viewedError: undefined,
    },
    {
        [viewedSurveyStarted]: (state) => {
            state.isSubmitting = true;
            state.isViewed = true;
            state.viewedError = undefined;
        },
        [viewedSurveySucceeded]: (state) => {
            state.isSubmitting = false;
        },
        [viewedSurveyError]: (state, action) => {
            state.isSubmitting = false;
            state.viewedError = action.payload;
        }
    }
);

const skip = createReducer(
    {
        isSubmitting: false,
        skipError: undefined,
    },
    {
        [skipSurveyStarted]: (state) => {
            state.isSubmitting = true;
            state.skipError = undefined;
        },
        [skipSurveySucceeded]: (state) => {
            state.isSubmitting = false;
        },
        [skipSurveyError]: (state, action) => {
            state.isSubmitting = false;
            state.skipError = action.payload;
        }
    }
);

export default combineReducers({
    surveys,
    viewed,
    vote,
    skip,
});
