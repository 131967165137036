import * as Sentry from '@sentry/browser';
import {createAction} from "@reduxjs/toolkit";
import ReactGA from 'react-ga';

import i18n from '../i18n';

import {
    sendOrder as sendOrderApi,
    sendRequest as sendRequestApi,
    getOrderStatus as getOrderStatusApi,
    getRrequestStatus as getRequestStatusApi,
} from '../MenuAPI';

import {
    showConfirmationPopup,
    setConfirmationPopupOrderId,
    showRequestConfirmationPopup,
    hidePlaceOrderConfirmationPopup,
} from "./ui";
import {
    getCartTotal, getCurrency,
    getLastOrderId,
    getOrder,
    getRestaurant,
    getWaiterRequestId,
    getWaiterRequestIsCompleted,
} from '../reducers/stateGetters';

export const addToCart = createAction('cart/addToCart');
export const removeFromCart = createAction('cart/removeFromCart');
export const updateItemQuantity = createAction('cart/updateItemQuantity');
export const createNewOrder = createAction('cart/createNewOrder');
export const clearCart = createAction('cart/clearCart');
export const clearPreviousOrders = createAction('cart/clearPreviousOrders');
export const clearPreviousDayOrders = createAction('cart/clearPreviousDayOrders');

export const orderSending = createAction('cart/orderSending');
export const orderSent = createAction('cart/orderSent');
export const orderError = createAction('cart/oderError');

export const orderStatusFetching = createAction('cart/orderStatusFetching');
export const updateOrderStatus = createAction('cart/updateOrderStatus');
export const orderStatusError = createAction('cart/orderStatusError');

export const requestSending = createAction('cart/requestSending');
export const requestSent = createAction('cart/requestSent');
export const requestError = createAction('cart/requestError');

export const requestStatusFetching = createAction('cart/requestStatusFetching');
export const updateRequestStatus = createAction('cart/updateRequestStatus');
export const requestStatusError = createAction('cart/requestStatusError');

/**
 * Try adding item to cart if cart is in new status.
 * @param cartItem
 * @return {Function}
 */
export const tryAddToCart = (cartItem) => (dispatch, getState) => {
    const orderStatus = getState().cart.currentOrder.status;

    if (orderStatus !== 'new') {
        let confirmEmptyCart = window.confirm(i18n.t('messages.confirmNewOrder'));
        if (confirmEmptyCart) {
            dispatch(createNewOrder());
        } else {
            return false;
        }
    }

    // Create sufficiently unique random ID
    cartItem.orderItemId = `${cartItem.id}-${Date.now()}`;
    dispatch(addToCart(cartItem));
    return true;
};

/**
 * Fetch order status.
 * @param tableCode
 * @param orderId
 * @return {Function}
 */
export const fetchOrderStatus = (tableCode, orderId) => (dispatch, getState) => {
    dispatch(orderStatusFetching());
    return getOrderStatusApi(tableCode, orderId).then(
        data => {
            // Decide if ConfirmationPopup should be displayed.
            if (getLastOrderId(getState()) !== orderId) {
                const order = getOrder(getState(), orderId);
                if (data.status !== order.status && data.status === 'accepted') {
                    dispatch(setConfirmationPopupOrderId(orderId));
                    dispatch(showConfirmationPopup());
                }
            }

            dispatch(updateOrderStatus(data));
            return data;
        },
        error => {
            Sentry.captureException(error);
            dispatch(orderStatusError({id: orderId, error: error.message}));
        }
    );
};

export const fetchRequestStatus = (tableCode, requestId) => (dispatch, getState) => {
    dispatch(requestStatusFetching());
    return getRequestStatusApi(tableCode, requestId).then(
        data => {
            // Decide if RequestStatusPopup should be displayed
            if (data.is_completed && !getWaiterRequestIsCompleted(getState())) {
                dispatch(showRequestConfirmationPopup());
            }

            dispatch(updateRequestStatus(data));
            return data;
        },
        error => {
            Sentry.captureException(error);
            dispatch(requestStatusError({id: requestId, error: error.message}));
        }
    )
};

/**
 * Send order.
 * @param tableCode
 * @param orderData
 * @return {function(*): Promise<AxiosResponse<any> | never | never>}
 */
export const sendOrder = (tableCode, orderData, additional_data={}, paymentType='') => (dispatch) => {
    dispatch(orderSending());
    return sendOrderApi(tableCode, {items: orderData, payment_type: paymentType, ...additional_data}).then(
        data => {
            dispatch(orderSent(data));
            dispatch(pullOrderStatus(tableCode, data.id));
            dispatch(hidePlaceOrderConfirmationPopup());
            dispatch(showConfirmationPopup());
        },
        error => {
            Sentry.captureException(error);
            dispatch(orderError(error.message));
        }
    );
};

/**
 * Send request to waiter.
 * @param tableCode
 * @param requestType
 * @param paymentType
 * @param requestBill
 * @return {Function}
 */
export const sendRequest = (tableCode, requestType, paymentType = '', requestBill = false, guestComment = '') => (dispatch) => {
    dispatch(requestSending());
    sendRequestApi(tableCode, requestType, paymentType, requestBill, guestComment)
        .then(
            data => {
                dispatch(requestSent(data));
                dispatch(pullRequestStatus(tableCode, data.id));
                dispatch(showRequestConfirmationPopup());
            },
            error => {
                Sentry.captureException(error);
                dispatch(requestError(error.message));
            }
        );
};

export const pullRequestStatus = (tableCode, requestId) => (dispatch, getState) => {
    let intervalId = window.setInterval(() => {
        dispatch(fetchRequestStatus(tableCode, requestId)).then(
            data => {
                // TODO: Find a better way to properly handle failure in status request.
                if (data && (data.is_completed || requestId !== getWaiterRequestId(getState()))) {
                    window.clearInterval(intervalId);
                }
            }
        );
    }, 3000);
};

/**
 * Pulls order status in intervals.
 * @param tableCode
 * @param orderId
 * @return {Function}
 */
export const pullOrderStatus = (tableCode, orderId) => (dispatch, getState) => {
    let intervalId = window.setInterval(() => {

        dispatch(fetchOrderStatus(tableCode, orderId)).then(
            data => {
                // TODO: Find a better way to properly handle failure in status request.
                if (data && (data.status === 'closed' || data.status === 'rejected')) {
                    window.clearInterval(intervalId);
                }
            }
        );
    }, 3000);
};

export const trackPurchase = () => (dispatch, getState) => {
    const restaurant = getRestaurant(getState());
    const total = getCartTotal(getState());
    const currency = getCurrency(getState());
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: `${restaurant.id}-${Date.now()}`,
        revenue: total,
        affiliation: restaurant.name,
        currency: currency,
    });
    ReactGA.plugin.execute('ecommerce', 'send', 'purchase');
};