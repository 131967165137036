import {timeToDate} from "../utils";

export const getRestaurant = state => state.data;
export const getRestaurantIsLoading = state => state.loading;
export const getRestaurantError = state => state.error;
export const getCurrency = state => {
    if (!state.data) return '';

    return state.data.currency;
};
export const getPreviousRestaurantId = state => state.previousRestaurantId;
export const getOrdersDisabled = state => state.data && state.data.orders_disabled;
export const getRestaurantDefaultLanguage = state => {
    const restaurant = getRestaurant(state);
    for (let lang of restaurant.languages) {
        if(lang.id === restaurant.default_language) {
            return lang;
        }
    }
    return restaurant.languages[0];
};

export const getCurrentWorkingHours = state => {
    const weekDay = new Date().getDay();
    const weekDayIndex = weekDay - 1 < 0 ? 6 : weekDay - 1;
    const workHours = getRestaurant(state).work_hours;
    return workHours.find(val => val.day === weekDayIndex)
};

export const isDeliveryAvailable = state => {
    const now = new Date();
    const workingHours = getCurrentWorkingHours(state);
    if (workingHours) {
        const closeTime = timeToDate(workingHours.close_time);
        const openTime = timeToDate(workingHours.open_time);
        if (closeTime > openTime) {
            // Close time before midnight
            return now > openTime && now < closeTime;
        } else {
            // Close time after midnight
            return now > openTime || now < closeTime;
        }
    }
    return false;
};

export const isDeliveryEnabled = state => {
    const restaurant = getRestaurant(state);
    return restaurant.delivery_enabled;
};

export const getDeliveryPhone = state => {
    return getRestaurant(state).delivery_phone;
};

export const getDeliveryPriceText = state => {
    const restaurant = getRestaurant(state);
    return restaurant.delivery_price;
};

export const getRestaurantOrderTypes = state => {
    return getRestaurant(state).order_types;
};

export const isAcceptingSms = state => {
    return getRestaurant(state).is_accepting_sms;
}

export const canUseDelivery = state => {
    return getRestaurant(state).can_use_delivery;
}

export const canUseWaiterCall = state => {
    return getRestaurant(state).can_user_waiter_call;
};

export const canUseOrdering = state => {
    return getRestaurant(state).can_use_ordering;
};

export const getBanners = state => {
    const restaurant = getRestaurant(state);
    return restaurant && restaurant.banners ? restaurant.banners : [];
};

export const getShowContactPhone = state => {
    return getRestaurant(state).show_contact_phone;
};

export const canUseRequestBill = state => {
    return getRestaurant(state).can_use_request_bill;
};

export const canUsePaymentTypeCard = state => {
    return getRestaurant(state).can_use_payment_type_card;
};

export const canUsePaymentTypeRoom = state => {
    return getRestaurant(state).can_use_payment_type_room;
};

export const canSendPaymentTypeWithOrder = state => {
    return getRestaurant(state).can_send_payment_type_with_order;
};

export const getSelectedBanner = state => {
    return state.selectedBanner;
};

export const isCartDisabled = state => {
    let restaurant = getRestaurant(state);

    // Fix this hack when API sends correct data
    if ([946, 1].indexOf(restaurant.id) !== -1) {
        if (!isAcceptingSms(state)) {
            return true;
        }
    }
    return false;
};
