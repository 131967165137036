import ReactGA from "react-ga";
import * as PropTypes from "prop-types";
import React from "react";

export function DeliveryPhoneLink(
    {
        phone,
        componentLocation,
        restaurantName,
        className = '',
    }) {
    const onPhoneClick = e => {
        e.preventDefault();
        const linkHref = e.target.href;

        const hitCallback = () => {
            if (!safetyCallbackCalled) {
                clearTimeout(safetyTimeout);
            }
            window.location.href = linkHref;
        };

        ReactGA.ga('send', 'event', {
            eventCategory: 'Call restaurant',
            eventAction: 'Call - ' + componentLocation,
            eventLabel: restaurantName,
            hitCallback: hitCallback,
        });

        let safetyCallbackCalled = false;
        const safetyCallback = () => {
            safetyCallbackCalled = true;
            hitCallback();
        };
        const safetyTimeout = setTimeout(safetyCallback, 250);
    };
    return <a className={className} href={`tel:${phone}`} onClick={onPhoneClick}>{phone}</a>;
}

DeliveryPhoneLink.propTypes = {
    className: PropTypes.string,
    phone: PropTypes.any.isRequired,
    componentLocation: PropTypes.string.isRequired,
    restaurantName: PropTypes.string.isRequired,
};