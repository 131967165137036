import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import {
	getCurrentWorkingHours,
	getRestaurant,
	getRestaurantError,
	getRestaurantIsLoading,
	isDeliveryEnabled,
	getDeliveryPriceText,
	getShowContactPhone,
	getDeliveryPhone,
} from '../reducers';

import LanguageSelector from './LanguageSelector';

import CookiePolicy from './CookiePolicy';
import NotFound from './NotFound';
import IconGo from './icons/IconGo';
import VersionDisplay from "./VersionDisplay";
import WorkingHours from './WorkingHours';
import {DeliveryPhoneLink} from "./DeliveryPhoneLink";
import BottomBanner from "./BottomBanner";

const Landing = (
	{
		match,
		loading,
		error,
		restaurant,
		languageCountries,
		workingHours,
		isDeliveryEnabled,
		deliveryPriceText,
		showContactPhone,
		deliveryPhone,
	}
	) => {

	const [t] = useTranslation();
	const [showCookie, setShowCookie] = useState(false);

	const onShowCookiePolicy = (e) => {
		e.preventDefault();
		setShowCookie(true);
	};

	if (error) {
		return <NotFound showBackButton={false} message={error.message}/>
	}
	if (loading || !restaurant) {
		return <h2>{t('messages.loading')}</h2>;
	}
	if (showCookie) {
		return <CookiePolicy onHide={() => setShowCookie(false)}/>;
	}

	const bg = restaurant.guest_app_background_image ? restaurant.guest_app_background_image.default : '';
	return (
		<div className="landing-outer">

			<figure>
				{restaurant.logo ?
					<img src={restaurant.logo.small} alt="Logo" />
				:
					<h1>{restaurant.name}</h1>
				}
			</figure>

			<div className="landing-choice">

				<LanguageSelector countries={languageCountries}/>
				<Link to={`${match.url}/category`} className="btn cta"><IconGo />{t('buttons.start')}</Link>

				{isDeliveryEnabled &&
					<>
						<WorkingHours workingHours={workingHours} t={t}/>
						<p className="delivery-price">{deliveryPriceText}</p>
					</>
				}
				{!isDeliveryEnabled && showContactPhone &&
					<p className="delivery-phone">
						{t('messages.deliveryPhone')}:{' '}
						<DeliveryPhoneLink
							phone={deliveryPhone}
							restaurantName={restaurant.name}
							componentLocation={'Menu'}
						/>
					</p>
				}

				<div className="disclaimer">
					By clicking on button above you are accepting <a href="#cookie" onClick={onShowCookiePolicy}>Cookie&nbsp;policy.</a>
					{' '}For support visit <a href="https://www.kono.bar/" target="_blank" rel="noreferrer">www.kono.bar</a>
				</div>

			</div>


			<div
				className="intro-bgr"
				style={ { backgroundImage: `url(${bg})` } }
			>
				<VersionDisplay/>

			</div>

			<BottomBanner position="landing" displayPositionOnly={true}/>

		</div>
	);
};

const mapStateToProps = (state) => {
	const restaurant = getRestaurant(state);
	const languageCountries = restaurant ? restaurant.languages : [];
	return {
		loading: getRestaurantIsLoading(state),
		restaurant,
		error: getRestaurantError(state),
		languageCountries,
		workingHours: getCurrentWorkingHours(state),
		isDeliveryEnabled: isDeliveryEnabled(state),
		deliveryPriceText: getDeliveryPriceText(state),
		showContactPhone: getShowContactPhone(state),
		deliveryPhone: getDeliveryPhone(state),
	}
};

export default connect(mapStateToProps)(Landing);
