import {combineReducers} from "redux";

import menu from './menu';
import restaurant from './restaurant';
import table from './table';
import cart from './cart';
import ui from './ui';
import timer from './timer';
import deliveryData from "./deliveryData";
import surveys from "./surveys";
import session from "./session";

// Export getters
export * from './stateGetters';

export default combineReducers({
    menu,
    restaurant,
    table,
    cart,
    ui,
    timer,
    deliveryData,
    surveys,
    session,
});
