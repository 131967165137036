import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

/**
 * Fetch menu for table code.
 * @param tableCode
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchMenu = (tableCode, config = {}) => {
    return api.get(`${tableCode}/categories/`, config)
        .then(response => response.data);
};

/**
 * Fetch restaurant for table code.
 * @param tableCode
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const fetchRestaurant = (tableCode) => {
    return api.get(`${tableCode}/restaurants/`);
};

/**
 * Create new waiter request.
 * @param tableCode
 * @param requestType
 * @param paymentType
 * @param requestBill
 * @param guestComment
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const sendRequest = (tableCode, requestType, paymentType, requestBill, guestComment='') => {
    return api.post(`${tableCode}/request/`, {
        request_type: requestType,
        payment_type: paymentType,
        bill_for_company: requestBill,
        guest_comment: guestComment,
    }).then(response => response.data);
};

/**
 * Creates new order.
 * @param tableCode
 * @param orderData
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const sendOrder = (tableCode, orderData) => {
    return api.post(`${tableCode}/order/`, orderData)
        .then(response => response.data);
};

/**
 * Returns eligible surveys for the guest.
 * @param tableCode
 * @return {Promise<AxiosResponse<any>>}
 */
export const fetchSurveys = (tableCode) => {
    return api.get(`${tableCode}/surveys/`)
        .then(response => response.data);
};

/**
 * Send survey answer and load results.
 * @param tableCode
 * @param survey
 * @param answer
 * @param guestSession
 * @return {AxiosPromise<any>}
 */
export const submitAnswer = (tableCode, survey, answer, guestSession) => {
    return api.post(`${tableCode}/surveys/${survey}/vote/`, {
        answer,
        guest_session: guestSession,
    })
        .then(response => response.data);
};

/**
 * Send notification that user has viewed the survey.
 * @param tableCode
 * @param survey
 * @param guestSession
 * @return {Promise<AxiosResponse<any>>}
 */
export const viewedSurvey = (tableCode, survey, guestSession) => {
    return api.post(`${tableCode}/surveys/${survey}/viewed/`, {
        guest_session: guestSession,
    })
        .then(response => response.data);
};

/**
 * Mark survey as skipped by user.
 * @param tableCode
 * @param survey
 * @param guestSession
 * @return {Promise<AxiosResponse<any>>}
 */
export const skipSurvey = (tableCode, survey, guestSession) => {
    return api.post(`${tableCode}/surveys/${survey}/skip/`, {
        guest_session: guestSession,
    })
        .then(response => response.data);
};

/**
 * Fetch order status by order short id.
 * @param tableCode
 * @param orderId
 * @return {Promise<AxiosResponse<any> | never>}
 */
export const getOrderStatus = (tableCode, orderId) => {
    return api.get(`${tableCode}/order/${orderId}/`)
        .then(response => response.data);
};

/**
 * Fetch waiter request status by short id.
 * @param tableCode
 * @param requestId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getRrequestStatus = (tableCode, requestId) => {
    return api.get(`${tableCode}/request/${requestId}/`)
        .then(response => response.data);
};

/**
 * Send analytics data for brand article view.
 * @param tableCode
 * @param brandArticleID
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendBrandArticleView = (tableCode, brandArticleID) => {
    return api.post(`${tableCode}/stats/brand-article-view/`, {brand_article: brandArticleID})
        .then(response => response.data);
}

/**
 * Send analytics data for brand items view in list
 * @param tableCode
 * @param itemIds
 * @param viewType category or sponsored
 * @return {Promise<*>}
 */
export const sendGroupBrandArticleView = (tableCode, itemIds, viewType) => {
    return api.post(`${tableCode}/stats/group-brand-article-view/${viewType}/`, itemIds.map(item => ({brand_article: item})))
        .then(response => response.data);
}

export const changeLanguage = (language) => {
    return api.post('language/', {language});
};