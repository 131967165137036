import * as Sentry from "@sentry/browser";
import { createAction } from "@reduxjs/toolkit";
import { fetchMenu as apiFetchMenu } from '../MenuAPI';


export const fetchMenuStarted = createAction('menu/fetch');
export const fetchMenuSucceeded = createAction('menu/fetchSucceeded');
export const fetchMenuError = createAction('menu/fetchError');

/**
 * Async action for fetching menu.
 * @param tableCode
 * @return {Function}
 */
export const fetchMenu = (tableCode) => (dispatch) => {
    dispatch(fetchMenuStarted());
    apiFetchMenu(tableCode).then(
        categories => {
            dispatch(fetchMenuSucceeded(categories))
            return categories;
        },
        error => {
            Sentry.captureException(error);
            dispatch(fetchMenuError(error.message));
            throw error;
        }
    );
};