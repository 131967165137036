import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import {connect} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {AnimatedSwitch, spring} from "react-router-transition";
import ReactGA from 'react-ga';

import {setTableCode} from "../actions/table";
import {fetchRestaurant} from "../actions/restaurant";
import {fetchMenu} from "../actions/menu";
import {requestChangeLanguage} from "../actions/ui";
import {fetchSurvey} from "../actions/surveys";
import {
	getRestaurant,
	getCurrentLanguage,
	getRestaurantError,
	getRestaurantIsLoading,
	getMenuError,
	getMenuIsLoading,
	getMenu,
	getOrderStatus,
	getConfirmationPopupVisible,
	getLastOrderId,
	getTableCode,
	getRequestConfirmationPopupVisible,
	getPlaceOrderConfirmationPopupVisible,
	getFirstSurvey,
	getSurveyPopupVisible,
} from "../reducers";
import {pullOrderStatus} from "../actions/cart";

import Landing from './Landing';
import Meals from './Meals';
import Category from './Category';
import Single from './Single';
import Cart from './Cart';
import PreviousOrders from './PreviousOrders';
import NotFound from './NotFound';
import InfoMessage from './InfoMessage';
import ConfirmationPopup from './ConfirmationPopup';
import Spinner from './Spinner';
import RequestConfirmationPopup from './RequestConfirmationPopup';
import PlaceOrderConfirmationPopup from "./PlaceOrderConfirmationPopup";
import PromoPage from "./PromoPage";
import Survey from "./Survey";


/**
 * Return preferred language or first if not available from list
 * of restaurant languages.
 * @param languageList
 * @param preferredLanguage
 * @return string
 */
const getAvailableLanguage = (languageList, preferredLanguage) => {
    const foundLanguage = languageList.find((item) => item.code === preferredLanguage);
    return foundLanguage ? preferredLanguage : languageList[0].code;
};

const mapStyles = (styles) => {
	return {
		opacity: styles.opacity,
		// transform: `scale(${styles.scale})`,
	};
};

const bounce = (val) => {
	return spring(val, {
		stiffness: 330,
		damping: 22,
	});
};

const bounceTransition = {
	atEnter: {
		opacity: 0,
		// scale: 1.2,
	},
	atLeave: {
		opacity: bounce(0),
		// scale: bounce(0.8),
	},
	atActive: {
		opacity: bounce(1),
		// scale: bounce(1),
	}
};

class Main extends Component {

	componentDidMount() {
		const {
			match: {params: {table}},
			setTableCode,
			fetchRestaurant,
			fetchMenu,
			requestChangeLanguage,
            currentLanguage,
			fetchSurvey,
		} = this.props;

		// Set table code and fetch restaurant and menu
		setTableCode(table);

		// Add extra data to Sentry
		Sentry.setExtra('link_code', table);

		fetchRestaurant(table).then(
			data => {
				requestChangeLanguage(getAvailableLanguage(data.languages, currentLanguage));
				fetchMenu(table);
				fetchSurvey(table);
			},
			error => {
				console.warn(error);
			}
		);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			restaurant,
			orderStatus,
			lastOrderId,
			pullOrderStatus,
			table,
		} = this.props;

		// Setup initial state when restaurant is loaded
		if (restaurant && restaurant !== prevProps.restaurant) {
			// Set restaurant theme
			const {guest_app_theme, guest_app_accent_color} = restaurant;
			const mainTheme = `theme-${guest_app_theme}`;
			const accentColor = guest_app_accent_color === 'default' ? '' : `addon-theme-${guest_app_accent_color}`;
			document.body.className = `${mainTheme} ${accentColor}`;

			// Set Restaurant ID in GA
			ReactGA.set({dimension1: restaurant.id.toString()});

			// Start pulling order status if order is not new or finished
			if (lastOrderId !== '' && orderStatus !== 'rejected' && orderStatus !== 'closed' && orderStatus !== 'new') {
				pullOrderStatus(table, lastOrderId);
			}

			// TODO: Also start pulling status for orders in previous order list that are not completed
		}
	}

	render() {
		const {
			match,
			restaurantError,
			restaurantIsLoading,
			menuError,
			menuIsLoading,
			menu,
			restaurant,
			confirmationPopupVisible,
			requestConfirmationPopupVisible,
			placeOrderConfirmationPopupVisible,
			survey,
			surveyPopupVisible,
		} = this.props;

		const error = restaurantError || menuError;
		if (error) {
			return <NotFound showBackButton={false} message={error}/>
		}

		if (restaurantIsLoading ||
			menuIsLoading ||
			!restaurant ||
			menu.length === 0
		) {
			return <Spinner/>;
		}

		if (surveyPopupVisible) {
			return <Survey survey={survey}/>;
		}

		if (placeOrderConfirmationPopupVisible) {
			return <PlaceOrderConfirmationPopup/>;
		}

		if (confirmationPopupVisible) {
			return <ConfirmationPopup/>
		}

		if (requestConfirmationPopupVisible) {
			return <RequestConfirmationPopup/>;
		}

		return (
			<main>
				<AnimatedSwitch
					atEnter={bounceTransition.atEnter}
					atLeave={bounceTransition.atLeave}
					atActive={bounceTransition.atActive}
					mapStyles={mapStyles}
					className="switch-wrapper"
				>
					<Route exact strict path={match.path} component={Landing} />
					{/* Cutoff the trailing slash from landing path with redirect */}
					<Redirect exact strict from={`${match.path}/`} to={match.path}/>

					<Route path={`${match.path}/category`} component={Category} />
					<Route path={`${match.path}/meals/:id`} component={Meals} />
					<Route path={`${match.path}/single/:id`} component={Single} />
					<Route path={`${match.path}/cart`} component={Cart} />
					<Route path={`${match.path}/previousOrders`} component={PreviousOrders} />
					<Route path={`${match.path}/promo`} component={PromoPage} />
					<Route path="" component={NotFound} />
				</AnimatedSwitch>

				<InfoMessage/>

			</main>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		restaurant: getRestaurant(state),
        currentLanguage: getCurrentLanguage(state),
		restaurantError: getRestaurantError(state),
		restaurantIsLoading: getRestaurantIsLoading(state),
		menuError: getMenuError(state),
		menuIsLoading: getMenuIsLoading(state),
		menu: getMenu(state),
		orderStatus: getOrderStatus(state),
		confirmationPopupVisible: getConfirmationPopupVisible(state),
		lastOrderId: getLastOrderId(state),
		table: getTableCode(state),
		requestConfirmationPopupVisible: getRequestConfirmationPopupVisible(state),
		placeOrderConfirmationPopupVisible: getPlaceOrderConfirmationPopupVisible(state),
		survey: getFirstSurvey(state),
		surveyPopupVisible: getSurveyPopupVisible(state),
	};
};

export default connect(mapStateToProps, {
	setTableCode,
	fetchRestaurant,
	fetchMenu,
	requestChangeLanguage,
	pullOrderStatus,
	fetchSurvey,
})(Main);