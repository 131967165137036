import React from "react";
import * as PropTypes from "prop-types";

export function SurveyAnswer({answer, selected, onSelect}) {
    const answerId = `survey-${answer.id}`;
    return (
        <span className="radio-item">
            <input type="radio" name="survey" id={answerId} value={answer.id} onChange={onSelect}
                   checked={parseInt(selected) === answer.id}/>
            <label htmlFor={answerId}><em>{answer.answer}</em></label>
        </span>
    );
}
SurveyAnswer.propTypes = {
    answer: PropTypes.object,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
};

export function SurveyAnswers({answers, selectedAnswer, onSelectAnswer}) {
    const onSelect = (e) => {
        onSelectAnswer(e.target.value);
    };

    return (
        <ul className="radio-item-list">
            {answers.map(item => (
                <li key={item.id}>
                    <SurveyAnswer answer={item} selected={selectedAnswer} onSelect={onSelect}/>
                </li>
            ))}
        </ul>
    );
}
SurveyAnswers.propTypes = {
    answers: PropTypes.array,
    selectedAnswer: PropTypes.string,
    onSelectAnswer: PropTypes.func.isRequired,
}
