import * as Sentry from '@sentry/browser';
import {createAction} from "@reduxjs/toolkit";

import {fetchRestaurant as apiFetchRestaurant} from '../MenuAPI';
import {clearCart, clearPreviousOrders} from "./cart";
import {hideConfirmationPopup} from "./ui";
import {setGSID} from "./session";


export const fetchRestaurantStarted = createAction('restaurant/fetchStarted');
export const fetchRestaurantSucceeded = createAction('restaurant/fetchSucceeded');
export const fetchRestaurantError = createAction('restaurant/fetchError');
export const setSelectedBanner = createAction('restaurant/setSelectedBanner');

export const fetchRestaurant= (tableCode) => (dispatch, getState) => {
    dispatch(fetchRestaurantStarted());
    return apiFetchRestaurant(tableCode)
        .then(response => {
            dispatch(setGSID(response.headers['gsid']));
            return response.data;
        }).then(
            restaurant => {
                const state = getState().restaurant;

                // If loaded restaurant is different from previously loaded restaurant
                // clear the cart and previous orders.
                if (state.previousRestaurantId !== restaurant.id) {
                    dispatch(clearCart());
                    dispatch(clearPreviousOrders());
                    dispatch(hideConfirmationPopup());
                }

                // Add extra data to Sentry
                Sentry.setExtra("restaurant_name", restaurant.name);
                Sentry.setExtra("restaurant_id", restaurant.id);

                dispatch(fetchRestaurantSucceeded(restaurant));
                return restaurant;
            },
            error => {
                Sentry.captureException(error);
                dispatch(fetchRestaurantError(error.message));
                throw error;
            }
        );
};