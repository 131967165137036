import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";

import {
    getOrdersDisabled,
    isDeliveryEnabled,
    canUseOrdering,
    isCartDisabled,
} from "../reducers";

import IconCart from "./icons/IconCart";
import IconReceipt from "./icons/IconReceipt";

function ButtonLabel({ordersDisabled, isDeliveryEnabled, canUseOrdering}) {
    const [t] = useTranslation();
    if (isDeliveryEnabled) {
        if (!ordersDisabled) {
            return (
                <>
                    <IconCart/>{t('buttons.addToCart')}
                </>
            );
        }
    } else if (canUseOrdering) {
        return (
            <>
                <IconCart/>{t('buttons.addToCart')}
            </>
        );
    }
    return (
        <>
            <IconReceipt/>{t('buttons.addToList')}
        </>
    );
}
ButtonLabel.propTypes = {
    ordersDisabled: PropTypes.bool,
    isDeliveryEnabled: PropTypes.bool,
    canUseOrdering: PropTypes.bool,
};

/**
 * Button for adding items to cart. Used on single item page.
 * @param onAddToCart
 * @param ordersDisabled
 * @param t
 * @return {*}
 * @constructor
 */
function AddToCartButton({onAddToCart, ordersDisabled, canUseOrdering, isDeliveryEnabled, isCartDisabled}) {

    if (isCartDisabled) return null;

    const addToCart = e => {
        e.preventDefault();
        onAddToCart();
    };

    return (
        <div className="cart-info">
            <div className="right-info">
                <a
                    href="#add"
                    className="btn medium"
                    onClick={addToCart}
                >
                    <ButtonLabel
                        isDeliveryEnabled={isDeliveryEnabled}
                        ordersDisabled={ordersDisabled}
                        canUseOrdering={canUseOrdering}
                    />
                </a>
            </div>
        </div>
    );
}
AddToCartButton.propTypes = {
    onAddToCart: PropTypes.func,
    ordersDisabled: PropTypes.bool,
    isDeliveryEnabled: PropTypes.bool,
    canUseOrdering: PropTypes.bool,
    isCartDisabled: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        ordersDisabled: getOrdersDisabled(state),
        canUseOrdering: canUseOrdering(state),
        isDeliveryEnabled: isDeliveryEnabled(state),
        isCartDisabled: isCartDisabled(state),
    };
};

export default connect(mapStateToProps)(AddToCartButton);