import React from "react";
import PropTypes from "prop-types";
import {generatePath, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';

import Thumbnail from "./Thumbnail";
import Dotdotdot from "react-dotdotdot";

/**
 * Display logo for brand articles.
 * @param brandItem
 * @return {JSX.Element|null}
 * @constructor
 */
function Logo({brandItem}) {
	if (brandItem && brandItem.logo) {
		return (
			<div className="article-featured-logo">
				<img src={brandItem.logo.small} alt="Brand logo"/>
			</div>
		);
	}
	return null;
}

function MenuItem(
	{
		item,
		table,
		fromCategory,
		icon,
		currency,
	}
) {
	const [t] = useTranslation();

	const isCombo = item.brand_article && item.brand_article.is_combo;
	const hasOldPrice = item.old_price && !isNaN(parseFloat(item.old_price)) ? parseFloat(item.old_price) > 0 : false;
	const isDiscountEnabled = item.is_discount_enabled && hasOldPrice;
	const articleClasses = classNames({'article-featured': item.is_sponsored && !isCombo});

	return (
		<article className={articleClasses}>
			{item.is_hidden ?
				<div className="inner-article unavailable">
					<Thumbnail
						thumbnail={item.thumbnail}
						icon={icon}
						name={item.name}
						isCombo={isCombo}
					/>
					<div className="content">
						<h3>{item.name}</h3>
						<h4>{t('messages.itemUnavailable')}</h4>
					</div>
				</div>
			:
				<Link
					to={{
						pathname: generatePath("/:table/single/:id", {table: table, id: item.id}),
						state: {fromCategory: fromCategory}
					}}
					className="inner-article"
				>
					<Thumbnail
						thumbnail={item.thumbnail}
						icon={icon}
						name={item.name}
						isCombo={isCombo}
						preferredSize={'medium'}
					/>

					<div className="content">
						<Dotdotdot clamp={2} tagName='h3'>
							{item.name}
						</Dotdotdot>
						{isDiscountEnabled ?
							<h4><span className="new">{item.price} {currency}</span> <span className="old">{item.old_price} {currency}</span></h4>
							:
							<h4>{item.price} {currency}</h4>
						}

					</div>
					{item.is_sponsored &&
						<Logo brandItem={item.brand_article}/>
					}
					<div className="right-options">
						<span className="action-icon add"><span></span></span>
					</div>
				</Link>
			}
		</article>
	);
}

MenuItem.propTypes = {
	table: PropTypes.string,
	item: PropTypes.object,
	fromCategory: PropTypes.number,
	icon: PropTypes.string,
	currency: PropTypes.string
};

export default MenuItem;