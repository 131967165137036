import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import Header from "./Header";
import MenuItem from "./MenuItem";
import {
    getSponsoredItems,
    getComboItems,
    getTableCode,
    getCurrency,
    getSelectedBanner,
} from "../reducers";
import {sendGroupBrandArticleView} from "../MenuAPI";

// const HERO_360 = '360';
const HERO_720 = '720';
// const HERO_1080 = '1080';

function PromoPage({sponsoredItems, comboItems, table, currency, banner}) {
    const [t] = useTranslation();
    const backLink = generatePath('/:table/category', {table: table});
    const analyticSentRef = useRef(false);

    // Send analytics data
    useEffect(() => {
        const itemIds = sponsoredItems.map(item => item.brand_article.id);
        const comboIds = comboItems.map(item => item.brand_article.id);
        const ids = itemIds.concat(comboIds);
        if (ids.length && !analyticSentRef.current) {
            analyticSentRef.current = true;
            sendGroupBrandArticleView(table, ids, 'sponsored');
        }
    }, [table, sponsoredItems, comboItems]);

    return (
        <div className="app-inner">
            <Header pageTitle={banner.name} goBackTo={backLink} backLabel={t('buttons.headerBackToMain')}/>

            <div className="banner-top">
                {banner.hero_image &&
                    <img src={banner.hero_image[HERO_720]} alt="Banner"/>
                }
            </div>

            {comboItems.length > 0 &&
                <>
                    <h2 className="content-title">{t('messages.comboMenu')}</h2>

                    <div className="article-list combo-list">
                        {comboItems.map(item =>
                            <MenuItem
                                key={item.id}
                                item={item}
                                table={table}
                                currency={currency}
                            />
                        )}
                    </div>
                </>
            }

            {sponsoredItems.length &&
                <>
                    <h2 className="content-title">{t('messages.sponsoredItems')}</h2>

                    <div className="article-list">
                        {sponsoredItems.map(item =>
                            <MenuItem
                                key={item.id}
                                item={item}
                                table={table}
                                currency={currency}
                            />
                        )}
                    </div>
                </>
            }

        </div>
    );
}
PromoPage.propTypes = {
    sponsoredItems: PropTypes.array,
    comboItems: PropTypes.array,
    table: PropTypes.string,
    currency: PropTypes.string,
    banner: PropTypes.object,
};

function mapStateToProps(state) {
    const banner = getSelectedBanner(state);
    const companyId = banner ? banner.brand_company : null;

    return {
        sponsoredItems: getSponsoredItems(state, companyId),
        comboItems: getComboItems(state, companyId),
        table: getTableCode(state),
        currency: getCurrency(state),
        banner,
    };
}

export default connect(mapStateToProps)(PromoPage);