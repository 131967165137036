import React from 'react';

const IconClose = () => {
    return (
        <svg className="btn-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" aria-labelledby="title" fill="#FFF">
            <title id="title">Close Icon</title>
            {/*<path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>*/}
            <path d="M 10,0 C 4.475806,0 0,4.47581 0,10 c 0,5.52419 4.475806,10 10,10 5.524197,0 9.999997,-4.47581 9.999997,-10 0,-5.52419 -4.4758,-10 -9.999997,-10 z m 4.903227,12.625 c 0.18951,0.18952 0.18951,0.49597 0,0.68548 l -1.59678,1.59275 c -0.18951,0.18951 -0.49596,0.18951 -0.68548,0 L 10,12.25806 7.375,14.90323 c -0.189517,0.18951 -0.495968,0.18951 -0.685484,0 L 5.096774,13.30645 c -0.189516,-0.18951 -0.189516,-0.49597 0,-0.68548 L 7.741935,10 5.096774,7.375 c -0.189516,-0.18952 -0.189516,-0.49597 0,-0.68548 L 6.693548,5.09274 c 0.189516,-0.18951 0.495968,-0.18951 0.685484,0 L 10,7.74194 12.624997,5.09677 c 0.18952,-0.18951 0.49597,-0.18951 0.68549,0 l 1.59677,1.59678 c 0.18952,0.18951 0.18952,0.49597 0,0.68548 L 12.258067,10 Z" />
        </svg>
    );
};

export default IconClose;