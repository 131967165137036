import React, { useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import Dotdotdot from "react-dotdotdot";
import _ from "lodash";

import {
	getMenu,
	getMenuIsLoading,
	getCategoryScrollTop,
	getRestaurant,
	isDeliveryEnabled,
	getDeliveryPhone,
	getFirstSurvey,
	getSurveyIsViewed,
	isCartDisabled,
} from '../reducers';

import {
	updateCategoryScrollTop,
	showSurveyPopup,
} from "../actions/ui";

import Header from './Header';
import CartInfo from './CartInfo';
import CallWaiterButton from './CallWaiterButton';
// import useLastScrollDirection from '../hooks/useLastScrollDirection';
import BottomBanner from './BottomBanner';
import Thumbnail from "./Thumbnail";
import {DeliveryPhoneLink} from "./DeliveryPhoneLink";


/**
 * Check if
 * @param icon
 * @return {boolean}
 */
function isPromoCategory({icon = ""}) {
	let parts = icon.split('/')
	let fileName = parts[parts.length-1]
	return _.startsWith(fileName, '_');
}

function Category(
	{
		match,
		categories,
		loading,
		oldScrollTop,
		updateCategoryScrollTop,
		restaurant,
		isDeliveryEnabled,
		deliveryPhone,
		survey,
		showSurveyPopup,
		surveyIsViewed,
		isCartDisabled,
	}) {

	const [t] = useTranslation();

	const containerRef = useRef();
	// const lastScrollDirection = useLastScrollDirection(containerRef, 'up');
	const lastScrollDirection = 'up';

	// Persist category scroll position
	useLayoutEffect(() => {
		let containerNode = containerRef.current;
		containerNode.scrollTop = oldScrollTop;

		return () => {
			updateCategoryScrollTop(containerNode.scrollTop);
		};
	}, [updateCategoryScrollTop, oldScrollTop]);

	// Show survey if available
	useEffect(() => {
		if (survey && !surveyIsViewed) {
			showSurveyPopup();
		}
	}, [survey, showSurveyPopup, surveyIsViewed]);

	const listClasses = classNames({
		'app-inner': true,
		'meals-and-category-list': true,
		'no-cart': isCartDisabled,
		[`scrolling-${lastScrollDirection}`]: true,
	});

	return (
		<div className={listClasses} ref={containerRef}>
			<Header pageTitle={t('messages.menu')}/>
			<CartInfo />
			<div className="article-list category">

				{isDeliveryEnabled &&
					<p className="delivery-phone">
						{t('messages.deliveryPhone')}:{' '}
						<DeliveryPhoneLink
							phone={deliveryPhone}
							restaurantName={restaurant.name}
							componentLocation={'Menu'}
						/>
					</p>
				}

				{!loading && categories && categories.map(item => {
					// Skip empty categories
					return item.menu_items.length ?
						<article key={item.id} className={classNames({promo: isPromoCategory(item)})}>
							<Link to={`/${match.params.table}/meals/${item.id}`} className="inner-article">
								<Thumbnail
									icon={item.icon}
									thumbnail={item.image}
									name={item.name}
									isPromo={isPromoCategory(item)}
								/>
								<div className="content">
									<Dotdotdot clamp={2} tagName='h2'>
										{item.name}
									</Dotdotdot>
								</div>
								<div className="right-options">
									<span className="action-icon more"><span></span></span>
								</div>
							</Link>
						</article>
						:
						null;
					}
				)}

			</div>

			<CallWaiterButton/>

			<BottomBanner position="category_list"/>

		</div>
	);
}
Category.propTypes = {
	updateCategoryScrollTop: PropTypes.func,
	oldScrollTop: PropTypes.number,
	categories: PropTypes.array,
	loading: PropTypes.bool,
	match: PropTypes.object,
	restaurant: PropTypes.object,
	isDeliveryEnabled: PropTypes.bool,
	deliveryPhone: PropTypes.string,
	survey: PropTypes.object,
	showSurveyPopup: PropTypes.func,
	surveyIsViewed: PropTypes.bool,
	isCartDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		categories: getMenu(state),
		loading: getMenuIsLoading(state),
		oldScrollTop: getCategoryScrollTop(state),
		restaurant: getRestaurant(state),
		isDeliveryEnabled: isDeliveryEnabled(state),
		deliveryPhone: getDeliveryPhone(state),
		survey: getFirstSurvey(state),
		surveyIsViewed: getSurveyIsViewed(state),
		isCartDisabled: isCartDisabled(state),
	}
};

export default connect(
	mapStateToProps,
	{
		updateCategoryScrollTop,
		showSurveyPopup,
	}
)(Category);
