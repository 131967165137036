/**
 * Cast time string hh:mm:ss to Date object.
 * @param time
 * @return {Date}
 */
export function timeToDate(time) {
    const [h, m, s] = time.split(':').map(v => parseInt(v));
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), h, m, s);
}

/**
 * Returns true if modifier has defined price and its not 0.
 * @param modifier
 * @return {boolean|boolean}
 */
export function modifierHasPrice(modifier) {
    if (modifier.hasOwnProperty('price')) {
        const price = parseFloat(modifier.price);
        return !isNaN(price) && price > 0;
    }
    return false;
}

/**
 * Returns unaccented string.
 * @param str
 * @return {string}
 */
export function unaccent(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Normalize modifiers
 * @param cartItems
 * @return {*}
 */
export let getPreparedItems = function (cartItems) {
    return cartItems.map(item => {
        const modifiers = [];
        for (const group in item.selectedModifiers) {
            if (isNaN(item.selectedModifiers[group])) {
                for (const modId in item.selectedModifiers[group]) {
                    const modVal = item.selectedModifiers[group][modId];
                    if (modVal) {
                        modifiers.push(parseInt(modId));
                    }
                }
            } else {
                modifiers.push(item.selectedModifiers[group]);
            }
        }
        return {
            ...item,
            modifiers
        }
    });
};

export function formatCartForMessage(cartItems, menu) {
    // Group cart items by category
    const groupedCartItems = {};
    cartItems.forEach((item) => {
        // Create category if not exists
        if (!groupedCartItems.hasOwnProperty(item.categoryId)) {
            groupedCartItems[item.categoryId] = {
                items: []
            };
        }
        groupedCartItems[item.categoryId].items.push(item);
    });

    // Create cart message
    let cartMessage = 'Detalji porudžbine:\n';
    Object.getOwnPropertyNames(groupedCartItems).forEach(catId => {
        const category = menu.find(cat => cat.id === parseInt(catId));
        cartMessage += `\n${category.name}:\n`;

        groupedCartItems[catId].items.forEach(cartItem => {
            const menuItem = category.menu_items.find(mi => mi.id === cartItem.id);
            cartMessage += `${menuItem.name} - x${cartItem.quantity}\n`;

            // Collect modifiers
            if (cartItem.selectedModifiers) {
                for (const modGroupId in cartItem.selectedModifiers) {
                    const modGroup = menuItem.modifier_groups.find(mg => mg.id === parseInt(modGroupId));
                    const selected = cartItem.selectedModifiers[modGroupId];
                    const selectedNames = [];
                    if (modGroup.group_type === 'multi') {
                        for (const modId in selected) {
                            if (selected[modId]) {
                                const mod = modGroup.modifiers.find(m => m.id === parseInt(modId));
                                selectedNames.push(mod.name);
                            }
                        }
                    } else {
                        const mod = modGroup.modifiers.find(m => m.id === parseInt(selected));
                        selectedNames.push(mod.name);
                    }

                    if (selectedNames.length) {
                        cartMessage += `- ${modGroup.name}: ${selectedNames.join(', ')}\n`;
                    }
                }
            }

            // Add comment
            if (cartItem.comment && cartItem.comment !== '') {
                cartMessage += `- Komentar: ${cartItem.comment.replace('\n', ' ')}\n`
            }
        });
    });
    return cartMessage;
}