export const getSurveys = state => state.surveys.data;
export const getSurveyIsLoading = state => state.surveys.loading;
export const getSurveyError = state => state.surveys.error;

/**
 * Returns first survey in list.
 * @param state
 * @return {null|Object}
 */
export const getFirstSurvey = state => {
    if (state.surveys.data.length) {
        return state.surveys.data[0];
    }
    return null;
}

export const getSurveyIsSubmitting = state => state.vote.isSubmitting;
export const getSurveyResult = state => state.vote.surveyResult;
export const getSubmitError = state => state.vote.submitError;

export const getSurveyIsViewed = state => state.viewed.isViewed;
