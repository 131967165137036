import {createReducer} from "@reduxjs/toolkit";

import {
    setGSID,
} from "../actions/session";

export default createReducer(
    {
        gsid: null,
    },
    {
        [setGSID]: (state, action) => {
            state.gsid = action.payload;
        },
    }
);
