import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {generatePath, withRouter} from "react-router-dom";

import {
    getTableCode,
    getWaiterRequestIsCompleted,
} from "../reducers";
import {
    hideRequestConfirmationPopup,
} from "../actions/ui";

import BottomBanner from './BottomBanner';

const RequestConfirmationPopup = ({
        table,
        history,
        hideRequestConfirmationPopup,
        waiterRequestIsCompleted,
    }) => {

    const [t] = useTranslation();

    const mainMenuPath = generatePath('/:table/category', {table: table});
    const handleBackToMainMenu = (e) => {
        e.preventDefault();
        hideRequestConfirmationPopup();
        history.push(mainMenuPath);
    };

    return (
        <div className="app-inner not-found">

            <div className="info-center">

                {!waiterRequestIsCompleted ?
                <>
                    <p>{t('messages.requestStatusOpen')}</p>

                    <p className="emphasize">{t('messages.waiterTip')}</p>
                </>
                :
                    <h1>{t('messages.requestStatusAccepted')}</h1>
                }

                <div className="inline-button-holder frame">
                    <a href="#back" onClick={handleBackToMainMenu} className="btn large inline flat">
                        <span className="btn-icon action-icon back"><span></span></span>
                        {t('buttons.backToHome')}
                    </a>
                </div>

            </div>

            <BottomBanner/>
        </div>
    );
};

RequestConfirmationPopup.propTypes = {
    table: PropTypes.string,
    history: PropTypes.object,
    waiterRequestIsCompleted: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        table: getTableCode(state),
        waiterRequestIsCompleted: getWaiterRequestIsCompleted(state),
    }
};

export default withRouter(connect(
    mapStateToProps,
    {
        hideRequestConfirmationPopup,
    }
)(RequestConfirmationPopup));