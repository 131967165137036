import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {generatePath} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Header from './Header';
import PreviousOrder from './PreviousOrder';
import {
    getPreviousOrders,
    getTableCode,
    getCurrency,
} from "../reducers";

function PreviousOrders(
    {
        previousOrders,
        table,
        currency,
    }
    ) {

    const [t] = useTranslation();

    const categoryLink = table !== '' ? generatePath('/:table/category', {table}) : '';

    return (
        <div className="app-inner cart history">
            <Header
                pageTitle={t('messages.orderHistoryTitle')}
                goBackTo={categoryLink}
                backLabel={t('buttons.headerBackToMain')}
            />


            <div className="article-list-simple">
                {previousOrders.map(item =>
                    <div className="article-simple" key={item.orderId}>
                        <PreviousOrder order={item} currency={currency}/>
                    </div>
                )}
            </div>
        </div>
    );
}

PreviousOrders.propTypes = {
    previousOrders: PropTypes.array,
    table: PropTypes.string,
    currency: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        previousOrders: getPreviousOrders(state),
        table: getTableCode(state),
        currency: getCurrency(state),
    };
}

export default connect(mapStateToProps)(PreviousOrders);