import React from 'react';

function IconDoor() {
    return (
        <svg className="btn-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="#fff">
            <title id="title">Bill Card Icon</title>
            <path d="M 24.375,17.499962 H 21.25 V 4.4316026 c 0,-1.0648438 -0.841016,-1.9316407 -1.875,-1.9316407 H 15 v 2.5 h 3.75 V 19.999962 h 5.625 c 0.345313,0 0.625,-0.279687 0.625,-0.625 v -1.25 c 0,-0.345312 -0.279687,-0.625 -0.625,-0.625 z M 12.196875,0.03941507 l -7.5,1.94296873 C 4.1402344,2.1265244 3.75,2.6444932 3.75,3.2390244 V 17.499962 H 0.625 c -0.3453125,0 -0.625,0.279688 -0.625,0.625 v 1.25 c 0,0.345313 0.2796875,0.625 0.625,0.625 H 13.75 V 1.2960557 c 0,-0.84296875 -0.764062,-1.46132813 -1.553125,-1.25664063 z M 10.3125,11.249962 c -0.5175781,0 -0.9375,-0.559766 -0.9375,-1.2500001 0,-0.6902343 0.4199219,-1.25 0.9375,-1.25 0.517578,0 0.9375,0.5597657 0.9375,1.25 0,0.6902341 -0.419922,1.2500001 -0.9375,1.2500001 z"/>
        </svg>
    );
}

export default IconDoor;
