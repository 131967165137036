import React, { Component } from 'react';

class IconReceipt extends Component {
 render() {
   return (
     <svg className="btn-icon receipt" xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" aria-labelledby="title" fill="#FFF">
		<title id="title">Receipt Icon</title>
        <path d="M14.6875,14 L5.3125,14 C5.140625,14 5,14.15 5,14.3333333 L5,15.6666667 C5,15.85 5.140625,16 5.3125,16 L14.6875,16 C14.859375,16 15,15.85 15,15.6666667 L15,14.3333333 C15,14.15 14.859375,14 14.6875,14 Z M17.1035714,2.23343456 L15.3558036,3.5170531 L13.8049107,2.37697018 C13.1299107,1.88074704 12.2299107,1.88074704 11.5508929,2.37697018 L10,3.5170531 L8.44910714,2.37697018 C7.77640948,1.88075343 6.86778695,1.88075343 6.19508929,2.37697018 L4.64419643,3.5170531 L2.89642857,2.23343456 C2.10089286,1.65109005 1,2.22933354 1,3.22588085 L1,21.774784 C1,22.7508262 2.08883929,23.3577768 2.89642857,22.7672302 L4.64419643,21.4795107 L6.19508929,22.6195936 C6.87008929,23.1158167 7.77008929,23.1158167 8.44910714,22.6195936 L10,21.4795107 L11.5508929,22.6195936 C12.2258929,23.1158167 13.1299107,23.1158167 13.8049107,22.6195936 L15.3558036,21.4795107 L17.1035714,22.7631292 C17.8991071,23.3454737 19,22.7672302 19,21.774784 L19,3.22588085 C19,2.24573761 17.9111607,1.64288801 17.1035714,2.23343456 Z M17,20.2923484 L15.3017045,19.0365736 L12.6488636,21 L9.99602273,19.0365736 L7.34318182,21 L4.69034091,19.0365736 L3,20.2923484 L3,4.70765159 L4.69829545,5.96342637 L7.35113636,4 L10.0039773,5.96342637 L12.6568182,4 L15.3096591,5.96342637 L17,4.70765159 L17,20.2923484 Z M14.6875,9 L5.3125,9 C5.140625,9 5,9.15 5,9.33333333 L5,10.6666667 C5,10.85 5.140625,11 5.3125,11 L14.6875,11 C14.859375,11 15,10.85 15,10.6666667 L15,9.33333333 C15,9.15 14.859375,9 14.6875,9 Z" transform="translate(-1 -2)"/>
      </svg>
   )
 }
}

export default IconReceipt;