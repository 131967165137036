import React from 'react';

function IconCall() {
    return (
        <svg className="btn-icon call" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" aria-labelledby="title" fill="#FFF">
            <title id="title">Call Icon</title>
            <path d="m 20.401116,14.839337 -4.593829,-1.96878 a 0.98439209,0.98439209 0 0 0 -1.148458,0.28301 l -2.03441,2.48559 A 15.203116,15.203116 0 0 1 5.3567337,8.3714665 l 2.48559,-2.03441 a 0.98193111,0.98193111 0 0 0 0.2830127,-1.14845 L 6.1565522,0.5947765 A 0.99095471,0.99095471 0 0 0 5.028603,0.0246465 l -4.26569911,0.98439 A 0.98439209,0.98439209 0 0 0 2.4346181e-8,1.9684065 C 2.4346181e-8,12.489097 8.5272965,20.999987 19.031581,20.999987 a 0.98439209,0.98439209 0 0 0 0.959782,-0.7629 l 0.984392,-4.2657 a 0.99628683,0.99628683 0 0 0 -0.574639,-1.13205 z"/>
        </svg>
    );
}

export default IconCall;