import React, {useEffect, useReducer} from "react";
import * as PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import classes from 'classnames';


const VALID_PHONE_REGEX = /[^0-9()/ -]/g;

function addressReducer(state, action) {
    switch (action.type) {
        case 'guest_name':
            return {...state, guest_name: action.value};
        case 'guest_phone':
            return {...state, guest_phone: action.value.replace(VALID_PHONE_REGEX, "")};
        case 'guest_municipality':
            return {...state, guest_municipality: action.value};
        case 'guest_address':
            return {...state, guest_address: action.value};
        case 'type':
            return {...state, type: action.value};
        default:
            throw new Error();
    }
}

function AddressForm(
    {
        onChange,
        values: {
            guest_name = '',
            guest_phone = '',
            guest_municipality = '',
            guest_address = '',
            type = 'delivery',
        },
        orderTypes = [],
    }
) {
    const [t] = useTranslation();

    const hasDeliveryOrder = orderTypes.indexOf('delivery') !== -1;
    const hasPickupOrder = orderTypes.indexOf('pickup') !== -1;

    // Check that initial order type is one of the available order types
    if (orderTypes.indexOf(type) === -1) {
        if (hasDeliveryOrder) {
            type = 'delivery';
        } else if (hasPickupOrder) {
            type = 'pickup';
        }
    }

    const [state, dispatch] = useReducer(
        addressReducer,
        {
            guest_name,
            guest_phone,
            guest_address,
            guest_municipality,
            type,
        });

    useEffect(() => {
        if (onChange) {
            let isValid = state.guest_name && state.guest_name !== '' && state.guest_phone && state.guest_phone !== '';
            if (state.type === 'delivery') {
                isValid = isValid && state.guest_municipality && state.guest_municipality !== '' && state.guest_address && state.guest_address !== '';
            }
            onChange(state, isValid);
        }
    }, [onChange, state]);

    return (
        <ul className="form-fields">
            {hasDeliveryOrder && hasPickupOrder &&
            <li className="mb-40">
                <label>{t('messages.deliveryOption')}</label>
                <span className="custom-radio">
                    <input
                        type="radio"
                        id="option-delivery"
                        name="type"
                        value="delivery"
                        checked={state.type === 'delivery'}
                        onChange={e => dispatch({type: 'type', value: e.target.value})}
                    />
                    <label htmlFor="option-delivery">
                        <span>{t('messages.selectDelivery')}</span>
                    </label>
                </span>
                <span className="custom-radio">
                    <input
                        type="radio"
                        id="option-pickup"
                        name="type"
                        value="pickup"
                        checked={state.type === 'pickup'}
                        onChange={e => dispatch({type: 'type', value: e.target.value})}
                    />
                    <label htmlFor="option-pickup">
                        <span>{t('messages.selectPickup')}</span>
                    </label>
                </span>
            </li>
            }
            <li>
                <label>
                {state.type === 'delivery' ? t('messages.enterDeliveryData') : t('messages.enterPickupData')}
                </label>
            </li>
            <li>
                <label htmlFor="guest_name">* {t('messages.deliveryName')}:</label>
                <input
                    type="text"
                    name="guest_name"
                    id="guest_name"
                    value={state.guest_name}
                    onChange={e => dispatch({type: 'guest_name', value: e.target.value})}
                />
            </li>
            <li className={classes({'mb-40': state.type === 'pickup'})}>
                <label htmlFor="guest_phone">* {t('messages.deliveryPhone')}:</label>
                <input
                    type="tel"
                    name="guest_phone"
                    id="guest_phone"
                    value={state.guest_phone}
                    onChange={e => dispatch({type: 'guest_phone', value: e.target.value})}
                />
            </li>
            {state.type === 'delivery' &&
            <>
                <li>
                    <label htmlFor="guest_municipality">* {t('messages.deliveryMunicipality')}:</label>
                    <input
                        type="text"
                        name="guest_municipality"
                        id="guest_municipality"
                        value={state.guest_municipality}
                        onChange={e => dispatch({type: 'guest_municipality', value: e.target.value})}
                    />
                </li>
                <li className="mb-40">
                    <label htmlFor="guest_address">* {t('messages.deliveryAddress')}:</label>
                    <textarea
                        name="guest_address"
                        id="guest_address"
                        value={state.guest_address}
                        onChange={e => dispatch({type: 'guest_address', value: e.target.value})}
                    />
                </li>
            </>
            }
        </ul>
    );
}
AddressForm.propTypes = {
    values: PropTypes.object,
    onChange: PropTypes.func,
    orderTypes: PropTypes.array.isRequired,
};

export default AddressForm;