import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {generatePath, withRouter} from "react-router-dom";

import {createNewOrder} from "../actions/cart";
import {
    getOrderStatus,
    getTableCode,
    getTickCount,
    getOrderCreatedAt,
    getLastOrderId,
    getConfirmationPopupOrderId,
    getOrder,
    getDeliveryTime,
    isDeliveryEnabled,
    getLastOrderType,
} from "../reducers";
import {
    hideConfirmationPopup,
    resetConfirmationPopupOrderId,
} from "../actions/ui";

import BottomBanner from './BottomBanner';

// const MAX_WAITING_TIME = 240000;

const ConfirmationPopup = ({
        orderStatus,
        table,
        // orderCreatedAt,
        // tickCount,
        history,
        hideConfirmationPopup,
        resetConfirmationPopupOrderId,
        createNewOrder,
        lastOrderId,
        currentOrderStatus,
        deliveryTime,
        isDeliveryEnabled,
        orderType,
    }) => {
    // let [longWait, setLongWait] = useState(false);
    //
    // // Measure how long the order is in open status and display
    // // warning message if guest is waiting too long.
    // useEffect(() => {
    //     if (orderStatus !== 'open') {
    //         setLongWait(false);
    //     } else {
    //         const orderDate = new Date(orderCreatedAt);
    //         const now = new Date();
    //         const timePassed = now - orderDate;
    //         if (timePassed > MAX_WAITING_TIME) {
    //             setLongWait(true);
    //         }
    //     }
    // }, [orderStatus, tickCount]);

    const [t] = useTranslation();

    const mainMenuPath = generatePath('/:table/category', {table: table});
    const handleBackToMainMenu = (e) => {
        e.preventDefault();
        hideConfirmationPopup();
        resetConfirmationPopupOrderId();
        if (currentOrderStatus !== 'new') {
            createNewOrder();
        }
        history.push(mainMenuPath);
    };

    return (
        <div className="app-inner not-found">

            <div className="info-center">

                {orderStatus === 'open' &&
                <>
                    <p>{t('messages.orderStatusOpen')}</p>

                    <p className="emphasize">{t('messages.waiterTip')}</p>
                </>
                }
                {orderStatus === 'accepted' &&
                    <>
                        <h1>{t('messages.orderStatusAccepted')}</h1>

                        {isDeliveryEnabled && deliveryTime &&
                            (orderType === 'delivery' ?
                                <p>{t('messages.deliveryTime', {deliveryTime})}</p>
                            :
                                <p>{t('messages.pickupTime', {deliveryTime})}</p>
                            )
                        }
                    </>
                }
                {orderStatus === 'rejected' &&
                    <h1>{t('messages.orderStatusRejected')}</h1>
                }
                {orderStatus === 'closed' &&
                    <h1>{t('messages.orderStatusClosed', {lastOrderId})}</h1>
                }

                <div className="inline-button-holder frame">
                    <a href="#back" onClick={handleBackToMainMenu} className="btn large inline flat">
                        <span className="btn-icon action-icon back"><span></span></span>
                        {t('buttons.backToHome')}
                    </a>
                </div>

            </div>

            <BottomBanner/>
        </div>
    );
};

ConfirmationPopup.propTypes = {
    orderStatus: PropTypes.string,
    currentOrderStatus: PropTypes.string,
    table: PropTypes.string,
    orderCreatedAt: PropTypes.string,
    tickCount: PropTypes.number,
    history: PropTypes.object,
    createNewOrder: PropTypes.func,
    lastOrderId: PropTypes.string,
    hideConfirmationPopup: PropTypes.func,
    resetConfirmationPopupOrderId: PropTypes.func,
    deliveryTime: PropTypes.number,
    isDeliveryEnabled: PropTypes.bool,
    orderType: PropTypes.string,
};

const mapStateToProps = (state) => {
    const orderId = getConfirmationPopupOrderId(state);
    const currentOrderStatus = getOrderStatus(state);

    let orderStatus = currentOrderStatus;
    let lastOrderId = orderId ? orderId : getLastOrderId(state);
    let orderCreatedAt = getOrderCreatedAt(state);

    if (orderId) {
        const order = getOrder(state, orderId);
        orderStatus = order.status;
        orderCreatedAt = order.createdAt;
    }
    return {
        table: getTableCode(state),
        tickCount: getTickCount(state),
        orderStatus,
        orderCreatedAt,
        lastOrderId,
        currentOrderStatus,
        deliveryTime: getDeliveryTime(state),
        isDeliveryEnabled: isDeliveryEnabled(state),
        orderType: getLastOrderType(state),
    }
};

export default withRouter(connect(
    mapStateToProps,
    {
        hideConfirmationPopup,
        createNewOrder,
        resetConfirmationPopupOrderId,
    }
)(ConfirmationPopup));