import {
    createReducer,
    combineReducers,
} from "@reduxjs/toolkit";

import {
    addToCart,
    removeFromCart,
    orderSending,
    orderError,
    orderSent,
    updateItemQuantity,
    updateOrderStatus,
    orderStatusError,
    createNewOrder,
    clearCart,
    clearPreviousOrders,
    clearPreviousDayOrders,
    requestSending,
    requestError,
    requestSent,
    updateRequestStatus,
} from '../actions/cart';

/**
 * Returns Date object set at the start of the current day.
 * Day starts at 5:00 AM
 * @return {Date}
 */
function getStartOfTheDay() {
    const result = new Date();
    if (result.getHours() < 5) {
        result.setDate(result.getDate() - 1);
    }
    result.setHours(5, 0, 0, 0);
    return result;
}

const previousOrders = (state = [], action, currentOrder = undefined) => {
    switch (action.type) {
        case createNewOrder.toString():
            if (currentOrder && currentOrder.orderId) {
                return [...state, currentOrder];
            }
            return state;
        case clearPreviousOrders.toString():
            return [];
        case clearPreviousDayOrders.toString():
            const startOfTheDay = getStartOfTheDay();
            return state.filter(val => (new Date(val.createdAt)) >= startOfTheDay);
        case updateOrderStatus.toString():
            return state.map(order => {
                if (order.orderId === action.payload.id) {
                    return {
                        ...order,
                        status: action.payload.status,
                    }
                }
                return order;
            });
        default:
            return state;
    }
};

const orderInitialState = {
    orderIsSending: false,
    orderSendError: null,
    cartItems: [],
    status: 'new',
    statusError: null,
    orderId: '',
    createdAt: null,
    deliveryTime: null,
    type: '',
};

const currentOrder = createReducer(
    orderInitialState,
    {
        [orderSending]: (state) => {
            state.orderIsSending = true;
            state.orderSendError = null;
        },
        [orderSent]: (state, action) => {
            state.orderIsSending = false;
            state.orderSendError = null;
            state.status = 'open';
            state.orderId = action.payload.id;
            state.createdAt = new Date().toISOString();
        },
        [orderError]: (state, action) => {
            state.orderIsSending = false;
            state.orderSendError = action.payload;
        },
        [addToCart]: (state, action) => {
            state.cartItems.push(action.payload);
        },
        [removeFromCart]: (state, action) => {
            state.cartItems.splice(action.payload, 1);
        },
        [updateItemQuantity]: (state, action) => {
            state.cartItems[action.payload.itemInd].quantity = action.payload.quantity;
        },
        [createNewOrder]: () => {
            return orderInitialState;
        },
        [updateOrderStatus]: (state, action) => {
            if (state.orderId === action.payload.id) {
                state.status = action.payload.status;
                state.deliveryTime = parseInt(action.payload.delivery_time) || null;
                state.type = action.payload.type;
                state.statusError = null;
            }
        },
        [orderStatusError]: (state, action) => {
            if (state.orderId === action.payload.id) {
                state.statusError = action.payload.error;
            }
        },
        [clearCart]: () => {
            return orderInitialState;
        },
    }
);

const waiterRequest = createReducer(
    {
        requestId: null,
        requestIsSending: false,
        error: null,
        isCompleted: false,
    },
    {
        [requestSending]: state => {
            state.requestIsSending = true;
            state.error = null;
            state.isCompleted = false;
            state.requestId = null;
        },
        [requestSent]: (state, action) => {
            state.requestIsSending = false;
            state.error = null;
            state.requestId = action.payload.id;
            state.isCompleted = false;
        },
        [requestError]: (state, action) => {
            state.requestIsSending = false;
            state.error = action.payload;
            state.isCompleted = false;
        },
        [updateRequestStatus]: (state, action) => {
            state.isCompleted = action.payload.is_completed;
        },
    }
);

const cartReducer = combineReducers({
    currentOrder,
    previousOrders,
    waiterRequest,
});

// We wrap cartReducer with this reducer because we need to read
// sibling state branches to create order history.
export default function cart(state, action) {
    switch (action.type) {
        case createNewOrder.toString():
            const currentOrder = {...state.currentOrder};
            return {
                ...cartReducer(state, action),
                previousOrders: previousOrders(
                    state.previousOrders, action, currentOrder
                )
            };
        default:
            return cartReducer(state, action);
    }
}
