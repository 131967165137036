import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import LinkButton from "./LinkButton";
import IconBill from "./icons/IconBill";
import IconCreditCard from "./icons/IconCreditCard";
import IconDoor from "./icons/IconDoor";

export function BillPaymentSelector({onPaymentSelected, canUseBillForCompany = true, ...props}) {
    const [t] = useTranslation();
    const [billForCompany, setBillForCompany] = useState(false);

    return (
        <div>

            <h2>{t('messages.paymentMethod')}</h2>

            <LinkButton
                onClick={() => onPaymentSelected('cash', billForCompany)}
            ><IconBill/>{t('buttons.payWithCash')}</LinkButton>

            {props.canUsePaymentTypeCard &&
            <LinkButton
                onClick={() => onPaymentSelected('card', billForCompany)}
            ><IconCreditCard/>{t('buttons.payWithCard')}</LinkButton>
            }

            {props.canUsePaymentTypeRoom &&
            <LinkButton
                onClick={() => onPaymentSelected('room', billForCompany)}
            ><IconDoor/>{t('buttons.payOnRoom')}</LinkButton>
            }

            {canUseBillForCompany &&
                <ul className="form-fields mt-40">
                    <ul className="mb-40">
                        <span className="custom-check">
                            <input
                                type="checkbox"
                                id="bill-for-company"
                                name="bill-for-company"
                                checked={billForCompany}
                                onChange={(e) => setBillForCompany(e.target.checked)}
                            />
                            <label htmlFor="bill-for-company"><span>{t('buttons.billForCompany')}</span></label>
                        </span>
                    </ul>
                </ul>
            }

        </div>
    );
}

BillPaymentSelector.propTypes = {
	canUsePaymentTypeCard: PropTypes.bool,
	canUsePaymentTypeRoom: PropTypes.bool,
    canUseBillForCompany: PropTypes.bool,
	onPaymentSelected: PropTypes.func,
};