import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, generatePath} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {OutboundLink} from "react-ga";

import LinkButton from "./LinkButton";
import IconClose from "./icons/IconClose";

import {setSelectedBanner} from "../actions/restaurant";

import {
	getBanners,
	getTableCode,
	getCurrentLanguage,
} from "../reducers";

import defaultBannerImage from '../pictures/kono_bar_digital_menu_banner.jpeg';

const BANNER_SIZE = '750x140';

const EMPTY_BANNER_URL = 'https://no-banner.kono.bar/'

const BANNER_TYPE_PROMO = 1;
const BANNER_TYPE_EXTERNAL_LINK = 2;
const BANNER_TYPE_INTERNAL_LINK = 3;

const defaultBanner = {
	image: {'750x140': defaultBannerImage},
	image_translations: [],
	url: 'https://www.kono.bar/',
};


function getInternalUrl(url) {
	return url.replace(window.location.origin + '/', '')
}


/**
 * Return list of banners that can be displayed for banner position.
 * @param banners
 * @param position
 * @param displayPositionOnly
 * @param tags
 * @return {{image: {"750x140"}, image_translations: *[]}|null|*}
 */
function selectBanner(banners, position, displayPositionOnly, tags = []) {
	let validBanners = [];

	// Iterate trough banners and validate if they are valid for current position
	for (const bannersKey in banners) {
		const banner = banners[bannersKey];

		// Check position if position is defined in banner
		if (position && position !== '') {
			if (banner.positions.indexOf(position) !== -1) {
				if (banner.category_tags.length) {
					for (const categoryTag of banner.category_tags) {
						if (tags.indexOf(categoryTag) !== -1) {
							validBanners.unshift(banner);
							break;
						}
					}
				} else {
					validBanners.unshift(banner);
				}
			} else if (banner.positions.length === 0 && !displayPositionOnly) {
				validBanners.push(banner);
			}
		} else if (banner.positions.length === 0) {
			// If position is not defined and banner has no position then it should be a default banner
			validBanners.push(banner);
		}
	}

	if (validBanners.length) {
		return validBanners[0];
	} else if (!displayPositionOnly) {
		return defaultBanner;
	} else {
		return null;
	}
}

function ExternalLinkWarning({url, onCancel}) {
	const [t] = useTranslation();

	return (
		<div className="banner active">
			<div className="background-overlay"></div>

			<div className="side-menu">

				<LinkButton
					className="medium close-popup"
					onClick={onCancel}
				>
					<IconClose/>{t('buttons.close')}
				</LinkButton>

				<div className="side-menu-content">
					<h2>
						{t('messages.externalLinkWarning')}
					</h2>

					<OutboundLink
						to={url}
						target="_blank"
						rel="noreferrer"
						className="btn"
						eventLabel="bannerLink"
					>{t('buttons.proceed')}</OutboundLink>

					<LinkButton
						href="#external-link"
						className="secondary"
						onClick={onCancel}
					>{t('buttons.cancel')}</LinkButton>
				</div>
			</div>
		</div>
	);
}
ExternalLinkWarning.propTypes = {
	url: PropTypes.string,
	onCancel: PropTypes.func,
};

/**
 * Banner component.
 * @param banners
 * @param table
 * @param currentLanguage
 * @param setSelectedBanner
 * @param position
 * @param displayPositionOnly
 * @param tags
 * @return {JSX.Element|null}
 * @constructor
 */
function BottomBanner(
	{
		banners,
		table,
		currentLanguage,
		setSelectedBanner,
		position = '',
		displayPositionOnly = false,
		tags = []
	}) {
	const [showWarning, setShowWarning] = useState(false);

	const onBannerClick = (e) => {
		e.preventDefault();

		setShowWarning(true);
	};

	const onCancel = () => {
		setShowWarning(false);
	};

	const banner = selectBanner(banners, position, displayPositionOnly, tags);

	if (!banner) return null;

	// If banner has EMPTY_BANNER_URL value don't show it
	if (banner.url === EMPTY_BANNER_URL) return null;

	// Determine banner type
	let bannerType = BANNER_TYPE_PROMO
	if (banner.url && banner.url !== '') {
		if (banner.url.indexOf(window.location.origin) === 0) {
			bannerType = BANNER_TYPE_INTERNAL_LINK;
		} else if (banner.url.startsWith('http')) {
			bannerType = BANNER_TYPE_EXTERNAL_LINK;
		}
	}

	const onPromoBannerClick = () => {
		switch (bannerType) {
			case BANNER_TYPE_INTERNAL_LINK:
				return generatePath(`/:table/${getInternalUrl(banner.url)}`, {table});
			case BANNER_TYPE_PROMO:
				// Set current banner for use in promo page only if it's not external link
				setSelectedBanner(banner);
				return generatePath('/:table/promo', {table});
			default:
				// Keep default behaviour
				return generatePath('/:table/promo', {table});
		}
	};

	// Get translated image
	let image = banner.image[BANNER_SIZE];
	for (let trImage of banner.image_translations) {
		if (trImage.language === currentLanguage) {
			image = trImage.image[BANNER_SIZE];
		}
	}

	return (
		<>
			{showWarning && <ExternalLinkWarning url={banner.url} onCancel={onCancel}/>}

			<div className="banner-bottom">

				{bannerType === BANNER_TYPE_EXTERNAL_LINK ?
					<a href="#external-link" onClick={onBannerClick}>
						<img src={image} alt="Banner"/>
					</a>
					:
					<Link to={onPromoBannerClick}>
						<img src={image} alt="Banner"/>
					</Link>
				}
			</div>
		</>
	);
}
BottomBanner.propTypes = {
	banners: PropTypes.array,
	table: PropTypes.string,
	currentLanguage: PropTypes.string,
	position: PropTypes.string,
	displayPositionOnly: PropTypes.bool,
	tags: PropTypes.array,
	setSelectedBanner: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		banners: getBanners(state),
		table: getTableCode(state),
		currentLanguage: getCurrentLanguage(state),
	}
}

export default connect(mapStateToProps, {setSelectedBanner})(BottomBanner);