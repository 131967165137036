import React from "react";
import * as PropTypes from "prop-types";
import {DeliveryPhoneLink} from "./DeliveryPhoneLink";
import WorkingHours from "./WorkingHours";
import {useTranslation} from "react-i18next";

function DeliveryInfo(
    {
        workingHours,
        deliveryPhone,
        componentLocation,
        restaurantName,
        deliveryPrice,
    }) {

    const [t] = useTranslation();

    return <div className="working-hours-container">
        <WorkingHours workingHours={workingHours} t={t}/>
        <p className="delivery-price">{deliveryPrice}</p>
        <p>{t('messages.deliveryPhone')}:{' '}
            <DeliveryPhoneLink
                phone={deliveryPhone}
                componentLocation={componentLocation}
                restaurantName={restaurantName}
            />
        </p>
    </div>;
}

DeliveryInfo.propTypes = {
    workingHours: PropTypes.object,
    deliveryPhone: PropTypes.string,
    componentLocation: PropTypes.string.isRequired,
    restaurantName: PropTypes.string.isRequired,
    deliveryPrice: PropTypes.string,
};

export default DeliveryInfo;