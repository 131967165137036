import React, {useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    submitSurvey,
    skipSurvey,
    viewedSurvey,
} from "../../actions/surveys";
import {hideSurveyPopup, showInfoMessage} from "../../actions/ui";
import {
    getSubmitError,
    getSurveyIsSubmitting,
    getSurveyResult,
    getTableCode,
} from "../../reducers";

import {SurveyResults} from "./SurveyResult";
import {SurveyAnswers} from "./SurveyAnswers";
import LinkButton from "../LinkButton";
import InfoMessage from "../InfoMessage";

function ModalHeader({title, onClose}) {
    return (
        <div className="modal-header">
            <h1>{title}</h1>
            <LinkButton onClick={onClose} className="close-modal"/>
        </div>
    );
}
ModalHeader.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
};

function Survey(
    {
        survey,
        surveySubmitting,
        surveyResult,
        submitSurvey,
        table,
        skipSurvey,
        viewedSurvey,
        hideSurveyPopup,
        showInfoMessage,
    }) {
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [t] = useTranslation();

    // Mark survey as viewed
    useEffect(() => {
        if (survey && table) {
            viewedSurvey(table, survey.id);
        }
    }, [viewedSurvey, survey, table]);

    // Skip survey
    const onSkip = () => {
        skipSurvey(table, survey.id);
        hideSurveyPopup();
    }

    // Submit survey vote
    const onSubmit = () => {
        submitSurvey(table, survey.id, parseInt(selectedAnswer))
            .then(() => {
                showInfoMessage(t('messages.surveyCompleted'));
            });
    }

    const onClosePopup = () => {
        // Skip survey if user did not voted.
        if (!surveyResult || !surveySubmitting) {
            skipSurvey(table, survey.id);
        }
        hideSurveyPopup();
    }

    return (
        <div className="modal-outer">

            <ModalHeader title={survey.title} onClose={onClosePopup}/>

            <div className="modal-content-outer">

                <img className="survey-hero" src={survey.hero_image['1080']} alt={survey.title}/>

                <div className="modal-content-inner">
                    <h3>{survey.question}</h3>

                    {!surveyResult &&
                    <SurveyAnswers
                        answers={survey.answers}
                        selectedAnswer={selectedAnswer}
                        onSelectAnswer={setSelectedAnswer}
                    />
                    }

                    {/* When survey sent */}
                    {surveyResult &&
                    <SurveyResults surveyResult={surveyResult} selectedAnswer={selectedAnswer}/>
                    }
                </div>

            </div>


            {!surveyResult && !surveySubmitting &&
                <div className="button-group bottom">
                    <div className="col">
                        <LinkButton
                            href="#skip-survey"
                            className="secondary"
                            onClick={onSkip}
                        ><span className="inline-icon close"></span>{t('buttons.surveySkip')}</LinkButton>
                    </div>
                    <div className="col">
                        <LinkButton
                            href="#submit-survey"
                            onClick={onSubmit}
                        ><span className="inline-icon ok"></span>{t('buttons.surveySubmit')}</LinkButton>
                    </div>
                </div>
            }

            {/* Submitting survey */}
            {!surveyResult && surveySubmitting &&
                <div className="button-group bottom">
                    <div className="col">
                        <LinkButton
                            href="#submit-survey"
                            className="loading disabled secondary"
                        ><span className="spinner"></span></LinkButton>
                    </div>
                </div>
            }

            {/* When survey sent */}
            {surveyResult &&
                <div className="button-group bottom">
                    <div className="col">
                        <LinkButton
                            href="#close-survey"
                            onClick={onClosePopup}
                        ><span className="inline-icon close"></span>{t('buttons.closeSurvey')}</LinkButton>
                    </div>
                </div>
            }

            <InfoMessage/>

        </div>
    );
}
Survey.propTypes = {
    survey: PropTypes.object,
    table: PropTypes.string,
    submitSurvey: PropTypes.func,
    surveySubmitting: PropTypes.bool,
    surveySubmitError: PropTypes.string,
    surveyResult: PropTypes.object,
    skipSurvey: PropTypes.func,
    viewedSurvey: PropTypes.func,
    hideSurveyPopup: PropTypes.func,
    showInfoMessage: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        table: getTableCode(state),
        surveyResult: getSurveyResult(state),
        surveySubmitting: getSurveyIsSubmitting(state),
        surveySubmitError: getSubmitError(state),
    }
}

export default connect(
    mapStateToProps,
    {
        submitSurvey,
        skipSurvey,
        viewedSurvey,
        hideSurveyPopup,
        showInfoMessage
    }
)(Survey);