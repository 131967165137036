import React, {useMemo} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import {getMenuItem} from "../reducers";

/**
 * Pad number with leading zero.
 * @param val
 * @return {string}
 */
function padNumber(val) {
	return val > 9 ? val.toString() : '0' + val;
}

/**
 * Translate order status.
 * Order status is mapped withs switch to t function calls so they can be picked upp with i18scan.
 * @param t
 * @param status
 * @return {*}
 */
function translateStatus(t, status) {
	switch (status) {
		case 'open':
			return t('messages.statusOpen');
		case 'closed':
			return t('messages.statusClosed');
		case 'accepted':
			return t('messages.statusAccepted');
		case 'rejected':
			return t('messages.statusRejected');
		default:
			return status;
	}
}

const PreviousOrder = ({order, currency, menuItems}) => {

	const [t] = useTranslation();

	const cartTotal = useMemo(() =>
        order.cartItems.reduce(
            (sum, item) => sum + (item.price * item.quantity)
        , 0)
    , [order.cartItems]).toFixed(2);

	const formattedTime = useMemo(() => {
	    const createdDate = new Date(order.createdAt);
	    return `${padNumber(createdDate.getHours())}:${padNumber(createdDate.getMinutes())}`;
	}, [order.createdAt]);

	return (
		<>
			<div className="headline">
				<p>{t('messages.orderNo', {lastOrderId: order.orderId})}</p>
				<p>{t('messages.orderCreatedAt')} {formattedTime}</p>
			</div>
			<table>
				<tbody>
					{menuItems.map((item, i) =>
						<tr key={i}>
							<th><p>{item.quantity}x</p></th>
							<td><p>{item.name}</p></td>
							<td><p>{item.price} {currency}</p></td>
						</tr>
					)}
					<tr>
						<th>
							<span className="icon-check"></span>
						</th>
						<td colSpan="2" className="total">
							<p>{translateStatus(t, order.status)} <span>{t('messages.total')} {cartTotal} {currency}</span></p>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};
PreviousOrder.propTypes = {
	order: PropTypes.object.isRequired,
	currency: PropTypes.string.isRequired,
    menuItems: PropTypes.array,
};

const mapStateToProps = (state, {order: {cartItems}}) => {
    const menuItems = cartItems.map(item => ({...getMenuItem(state, item.id), ...item}));

    return {
        menuItems,
    }
};

export default connect(mapStateToProps)(PreviousOrder);