
export const getMenu = state => state.data;
export const getMenuIsLoading = state => state.loading;
export const getMenuError = state => state.error;
export const getCategory = (state, categoryId) => {
    const categories = getMenu(state);
    return categories.find(item => item.id === categoryId);
};
export const getMenuItems = (state, categoryId) => {
    const category = getCategory(state, categoryId);
    return category ? category.menu_items : [];
};
export const getMenuItem = (state, menuItemId) => {
    for (let category of getMenu(state)) {
        let menuItem = category.menu_items.find(item => item.id === menuItemId);
        if (menuItem) {
            // Add category info to menu item
            if (category.category_info) {
                return {
                    ...menuItem,
                    category_info: category.category_info,
                    category_id: category.id,
                    category_icon: category.icon ? category.icon : null,
                };
            }
            return {...menuItem, category_info: []};
        }
    }
    return null;
};

const getAllSponsoredItems = (state, company) => {
    let sponsoredItems = [], itemIds = [];

    for (let category of getMenu(state)) {
        let menuItems = category.menu_items.filter(item => item.is_sponsored && itemIds.indexOf(item.id) === -1);
        itemIds.push(...menuItems.map(item => item.id));
        sponsoredItems.push(...menuItems);
    }

    return sponsoredItems;
}

export const getSponsoredItems = (state, companyId = null) => {
    if (companyId) {
        return getAllSponsoredItems(state)
            .filter(item =>
                !item.brand_article.is_combo && item.brand_article.company === companyId
            ).sort((a, b) => a.brand_article.ordering - b.brand_article.ordering);
    }
    return getAllSponsoredItems(state)
        .filter(item => !item.brand_article.is_combo);
}

export const getComboItems = (state, companyId = null) => {
    if (companyId) {
        return getAllSponsoredItems(state)
            .filter(item =>
                item.brand_article.is_combo && item.brand_article.company === companyId
            ).sort((a, b) => a.brand_article.ordering - b.brand_article.ordering);
    }
    return getAllSponsoredItems(state)
        .filter(item => item.brand_article.is_combo);
}

export const getUpsellItems = (state, upsellIds = []) => {
    let result = []
    for (const upsellId of upsellIds) {
        result.push(getMenuItem(state, upsellId));
    }
    // Filter nulls for items not currently in menu
    result = result.filter(item => item);
    return result.sort((a, b) => a.brand_article.ordering - b.brand_article.ordering);
};
