import {createReducer, combineReducers} from "@reduxjs/toolkit";

import {
    displayThankYouMessage,
    displayBillPaymentOption,
    displayWaiterOption,
    displayCallWaiterOption,
    displayCallWaiterWithoutLanguageOption,
    changeLanguage,
    showInfoMessage,
    showConfirmationPopup,
    hideConfirmationPopup,
    setConfirmationPopupOrderId,
    resetConfirmationPopupOrderId,
    updateCategoryScrollTop,
    updateMealsScrollTop,
    showRequestConfirmationPopup,
    hideRequestConfirmationPopup,
    showPlaceOrderConfirmationPopup,
    hidePlaceOrderConfirmationPopup,
    showSurveyPopup,
    hideSurveyPopup,
} from "../actions/ui";

const sideMenu = createReducer(
    {
        state: 'ready'
    },
    {
        [displayThankYouMessage]: (state, action) => {
            state.state = 'sent';
        },
        [displayBillPaymentOption]: (state, action) => {
            state.state = 'bill';
        },
        [displayCallWaiterOption]: (state, action) => {
            state.state = 'ready';
        },
        [displayCallWaiterWithoutLanguageOption]: (state, action) => {
            state.state = 'ready-no-language';
        },
        [displayWaiterOption]: state => {
            state.state = 'waiter';
        },
    }
);

const languageSelector = createReducer(
    {
        currentLanguage: null
    },
    {
        [changeLanguage]: (state, action) => {
            state.currentLanguage = action.payload;
        }
    }
);

const infoMessage = createReducer(
    null,
    {
        [showInfoMessage]: (state, action) => action.payload,
    }
);

const confirmationPopupVisible = createReducer(
    false,
    {
        [showConfirmationPopup]: () => true,
        [hideConfirmationPopup]: () => false,
    }
);

const confirmationPopupOrderId = createReducer(
    null,
    {
        [setConfirmationPopupOrderId]: (state, action) => action.payload,
        [resetConfirmationPopupOrderId]: () => null,
    }
);

const requestConfirmationPopupVisible = createReducer(
    false,
    {
        [showRequestConfirmationPopup]: () => true,
        [hideRequestConfirmationPopup]: () => false,
    }
);

const placeOrderConfirmationPopupVisible = createReducer(
    false,
    {
        [showPlaceOrderConfirmationPopup]: () => true,
        [hidePlaceOrderConfirmationPopup]: () => false,
    }
);

const categoryScrollTop = createReducer(
    0,
    {
        [updateCategoryScrollTop]: (state, action) => action.payload,
    }
);

const mealsScrollTop = createReducer(
    {},
    {
        [updateMealsScrollTop]: (state, action) => {
            state[action.payload.categoryId] = action.payload.scroll;
        },
    }
);

const surveyPopupVisible = createReducer(
    null,
    {
        [showSurveyPopup]: () => true,
        [hideSurveyPopup]: () => false,
    }
);

export default combineReducers({
    sideMenu,
    languageSelector,
    infoMessage,
    confirmationPopupVisible,
    confirmationPopupOrderId,
    categoryScrollTop,
    mealsScrollTop,
    requestConfirmationPopupVisible,
    placeOrderConfirmationPopupVisible,
    surveyPopupVisible,
});
