import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";

import BottomBanner from './BottomBanner';


class NotFound extends Component {
	static propTypes = {
		message: PropTypes.string,
		showBackButton: PropTypes.bool,
	};

	static defaultProps = {
	    message: '',
	    showBackButton: true,
    };

	render() {
		const {message, showBackButton, t} = this.props;

		return (
			<div className="app-inner not-found">
			
				<div className="info-center">
					<h1>{t('messages.somethingWentWrong')}</h1>
					<p>{message}</p>

					{showBackButton &&
					<Link to='/category' className="btn large inline">
						<span className="btn-icon action-icon back"><span></span></span>{t('buttons.backToHome')}
					</Link>
					}
				</div>
				
				<BottomBanner/>

			</div>
		);
	}
}

export default withTranslation()(NotFound);