import React  from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

const BackButton = ({history, to, label = ''}) => {

	return (
		<button className="action-icon back" onClick={() => history.push(to)}><span></span>{label}</button>
	);
};

BackButton.propTypes = {
	history: PropTypes.object,
	to: PropTypes.any,
	label: PropTypes.string,
};

export default withRouter(BackButton);