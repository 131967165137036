import * as fromCart from "./cartGetters";
import * as fromTable from "./tableGetters";
import * as fromMenu from "./menuGetters";
import * as fromRestaurant from "./restaurantGetters";
import * as fromUi from "./uiGetters";
import * as fromTimer from "./timerGetters";
import * as fromDeliveryData from "./deliveryDataGetters";
import * as fromSurvey from "./surveysGetters";
import * as fromSession from "./sessionGetters";

// restaurantGetters
export const getRestaurant = state => fromRestaurant.getRestaurant(state.restaurant);
export const getRestaurantIsLoading = state => fromRestaurant.getRestaurantIsLoading(state.restaurant);
export const getRestaurantError = state => fromRestaurant.getRestaurantError(state.restaurant);
export const getCurrency = state => fromRestaurant.getCurrency(state.restaurant);
export const getPreviousRestaurantId = state => fromRestaurant.getPreviousRestaurantId(state.restaurant);
export const getOrdersDisabled = state => fromRestaurant.getOrdersDisabled(state.restaurant);
export const getRestaurantDefaultLanguage = state => fromRestaurant.getRestaurantDefaultLanguage(state.restaurant);
export const isDeliveryAvailable = state => fromRestaurant.isDeliveryAvailable(state.restaurant);
export const getCurrentWorkingHours = state => fromRestaurant.getCurrentWorkingHours(state.restaurant);
export const isDeliveryEnabled = state => fromRestaurant.isDeliveryEnabled(state.restaurant);
export const getDeliveryPhone = state => fromRestaurant.getDeliveryPhone(state.restaurant);
export const getRestaurantOrderTypes = state => fromRestaurant.getRestaurantOrderTypes(state.restaurant);
export const isAcceptingSms = state => fromRestaurant.isAcceptingSms(state.restaurant);
export const canUseDelivery = state => fromRestaurant.canUseDelivery(state.restaurant);
export const getBanners = state => fromRestaurant.getBanners(state.restaurant);
export const canUseWaiterCall = state => fromRestaurant.canUseWaiterCall(state.restaurant);
export const canUseOrdering = state => fromRestaurant.canUseOrdering(state.restaurant);
export const getDeliveryPriceText = state => fromRestaurant.getDeliveryPriceText(state.restaurant);
export const getShowContactPhone = state => fromRestaurant.getShowContactPhone(state.restaurant);
export const canUseRequestBill = state => fromRestaurant.canUseRequestBill(state.restaurant);
export const canUsePaymentTypeCard = state => fromRestaurant.canUsePaymentTypeCard(state.restaurant);
export const canUsePaymentTypeRoom = state => fromRestaurant.canUsePaymentTypeRoom(state.restaurant);
export const canSendPaymentTypeWithOrder = state => fromRestaurant.canSendPaymentTypeWithOrder(state.restaurant);
export const getSelectedBanner = state => fromRestaurant.getSelectedBanner(state.restaurant);
export const isCartDisabled = state => fromRestaurant.isCartDisabled(state.restaurant);

// menuGetters
export const getMenu = state => fromMenu.getMenu(state.menu);
export const getMenuIsLoading = state => fromMenu.getMenuIsLoading(state.menu);
export const getMenuError = state => fromMenu.getMenuError(state.menu);
export const getCategory = (state, categoryId) => fromMenu.getCategory(state.menu, categoryId);
export const getMenuItems = (state, categoryId) => fromMenu.getMenuItems(state.menu, categoryId);
export const getMenuItem = (state, menuItemId) => fromMenu.getMenuItem(state.menu, menuItemId);
export const getSponsoredItems = (state, companyId = null) => fromMenu.getSponsoredItems(state.menu, companyId);
export const getComboItems = (state, companyId = null) => fromMenu.getComboItems(state.menu, companyId);
export const getUpsellItems = (state, upsellIds) => fromMenu.getUpsellItems(state.menu, upsellIds);

// tableGetters
export const getTableCode = state => fromTable.getTableCode(state.table);

// cartGetters
export const getCartItems = state => fromCart.getCartItems(state.cart);
export const getCartTotal = state => fromCart.getCartTotal(state.cart);
export const getOrderStatus = state => fromCart.getOrderStatus(state.cart);
export const getLastOrderId = state => fromCart.getLastOrderId(state.cart);
export const getOrderIsSending = state => fromCart.getOrderIsSending(state.cart);
export const getOrderError = state => fromCart.getOrderError(state.cart);
export const getOrderStatusError = state => fromCart.getOrderStatusError(state.cart);
export const getOrderCreatedAt = state => fromCart.getOrderCreatedAt(state.cart);
export const getPreviousOrders = state => fromCart.getPreviousOrders(state.cart);
export const getOrder = (state, orderId) => fromCart.getOrder(state.cart, orderId);
export const getWaiterRequestId = state => fromCart.getWaiterRequestId(state.cart);
export const getWaiterRequestIsSending = state => fromCart.getWaiterRequestIsSending(state.cart);
export const getWaiterRequestError = state => fromCart.getWaiterRequestError(state.cart);
export const getWaiterRequestIsCompleted = state => fromCart.getWaiterRequestIsCompleted(state.cart);
export const getDeliveryTime = state => fromCart.getDeliveryTime(state.cart);
export const getLastOrderType = state => fromCart.getLastOrderType(state.cart);

// uiGetters
export const getSideMenuState = state => fromUi.getSideMenuState(state.ui);
export const getCurrentLanguage = state => fromUi.getCurrentLanguage(state.ui);
export const getInfoMessage = state => fromUi.getInfoMessage(state.ui);
export const getConfirmationPopupVisible = state => fromUi.getConfirmationPopupVisible(state.ui);
export const getConfirmationPopupOrderId = state => fromUi.getConfirmationPopupOrderId(state.ui);
export const getCategoryScrollTop = state => fromUi.getCategoryScrollTop(state.ui);
export const getMealsScrollTop = (state, categoryId) => fromUi.getMealsScrollTop(state.ui, categoryId);
export const getRequestConfirmationPopupVisible = state => fromUi.getRequestConfirmationPopupVisible(state.ui);
export const getPlaceOrderConfirmationPopupVisible = state => fromUi.getPlaceOrderConfirmationPopupVisible(state.ui);
export const getSurveyPopupVisible = state => fromUi.getSurveyPopupVisible(state.ui);

// timerGetters
export const getTickCount = state => fromTimer.getTickCount(state.timer);

// deliveryDataGetters
export const getDeliveryData = state => fromDeliveryData.getDeliveryData(state.deliveryData);

// surveysGetters
export const getSurveys = state => fromSurvey.getSurveys(state.surveys);
export const getSurveyIsLoading = state => fromSurvey.getSurveyIsLoading(state.surveys);
export const getSurveyError = state => fromSurvey.getSurveyError(state.surveys);
export const getFirstSurvey = state => fromSurvey.getFirstSurvey(state.surveys);
export const getSurveyIsSubmitting = state => fromSurvey.getSurveyIsSubmitting(state.surveys);
export const getSurveyResult = state => fromSurvey.getSurveyResult(state.surveys);
export const getSubmitError = state => fromSurvey.getSubmitError(state.surveys);
export const getSurveyIsViewed = state => fromSurvey.getSurveyIsViewed(state.surveys);

// sessionGetters
export const getGSID = state => fromSession.getGSID(state.session);
