import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import {useTranslation} from "react-i18next";

import {
    // getCurrency,
    getTableCode,
    // getCartTotal,
	getOrdersDisabled,
	isDeliveryEnabled,
	canUseOrdering,
	isCartDisabled,
} from "../reducers";

import IconCart from './icons/IconCart';
import IconReceipt from './icons/IconReceipt';


function LinkLabel({ordersDisabled, isDeliveryEnabled, canUseOrdering}) {
	const [t] = useTranslation();
	if (isDeliveryEnabled) {
		if (!ordersDisabled) {
			return (
				<>
					<IconCart/>{t('buttons.viewCart')}
				</>
			);
		}
	} else if (canUseOrdering) {
		return (
			<>
				<IconCart/>{t('buttons.viewCart')}
			</>
		);
	}
	return (
		<>
			<IconReceipt />{t('buttons.viewList')}
		</>
	);
}
LinkLabel.propTypes = {
	ordersDisabled: PropTypes.bool,
	isDeliveryEnabled: PropTypes.bool,
	canUseOrdering: PropTypes.bool,
};

// currency and cartTotal not used currently
// function CartInfo({table, currency, cartTotal, t, ordersDisabled}) {
function CartInfo({table, ordersDisabled, isDeliveryEnabled, canUseOrdering, isCartDisabled}) {

	if(isCartDisabled) return null;

	// This resolves problem with first render while the table code is not set yet.
	const pathToCart = table !== '' ? generatePath('/:table/cart', {table: table}) : '';

	return (
		<div className="cart-info-container">
			<div className="cart-info">

				{/**
				<div className="left-info">
					<h3><span>{t('messages.total')}</span> {cartTotal} {currency}</h3>
				</div>
				**/}

				<div className="right-info">
					<Link to={pathToCart} className="btn large reduced-width">
						<LinkLabel
							ordersDisabled={ordersDisabled}
							canUseOrdering={canUseOrdering}
							isDeliveryEnabled={isDeliveryEnabled}
						/>
					</Link>
				</div>
			</div>
		</div>
	);
}
CartInfo.propTypes = {
	table: PropTypes.string,
	// currency: PropTypes.string,
	// cartTotal: PropTypes.string,
	ordersDisabled: PropTypes.bool,
	canUseOrdering: PropTypes.bool,
	isDeliveryEnabled: PropTypes.bool,
	isCartDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
	    table: getTableCode(state),
		// currency: getCurrency(state),
		// cartTotal: getCartTotal(state).toFixed(2),
		ordersDisabled: getOrdersDisabled(state),
		canUseOrdering: canUseOrdering(state),
		isDeliveryEnabled: isDeliveryEnabled(state),
		isCartDisabled: isCartDisabled(state),
	}
};

export default connect(mapStateToProps)(CartInfo);