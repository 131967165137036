import React from 'react';
import {useTranslation} from "react-i18next";

import IconClose from './icons/IconClose';
import LinkButton from "./LinkButton";


const CookiePolicy = ({onHide}) => {
    const [t] = useTranslation();

    return (
        <div className="app-inner not-found">

            <LinkButton className="medium close-popup" onClick={onHide}>
                <IconClose/>{t('buttons.close')}
            </LinkButton>

            <div className="cookie-policy">
                <h2>Politika privatnosti i pravilnik o kolačićima</h2>

                <h3>Politika privatnosti</h3>


                <h4>Opšte odredbe</h4>

                <p>Politika privatnosti zajedno sa Uslovima korišćenja i Pravilnikom o kolačićima kompanije Kono.bar d.o.o. Beograd (u daljem tekstu: Kompanija) predstavlja Ugovor koji se odnosi na korisnike.</p>

                <p>Kono.bar d.o.o. je svesno značaja zaštite podataka o ličnosti i pristupa zaštiti podataka o ličnosti veoma ozbiljno.</p>

                <p>Politiku privatnosti Kompanija je sačinila kako bi proces obrade podataka bio dostupan, transparentan i razumljiv.</p>


                <h4>Rukovalac</h4>

                <p>Politika privatnosti se primenjuje na obradu podataka o ličnosti od strane Kono.bar d.o.o., kako kroz upotrebu veb sajtova kono.bar i klopa.net (u daljem tekstu pojedinačno: Sajt, a zajedno: Sajtovi), tako i kroz korišćenje servisa kono.bar (u daljem tekstu: Servis).</p>

                <p>Rukovalac podacima je privredno društvo Kono. bar d.o.o. Beograd, Novi Beograd, ul. Bulevar Mihaila Pupina br. 35, matični broj 21458775, PIB: 111303650.</p>

                <p>Rukovaoca možete kontaktirati putem mejl adrese: info@kono.bar ili putem telefona: 011/212-22-58.</p>


                <h4>Podaci o ličnosti koje prikupljamo</h4>

                <p>Podaci koje prikupljamo zavise od načina na koji koristite Sajtove i Servise, te se razlikuju sledeći podaci:</p>
                <p><em>Podaci koje prikupljamo od korisnika dobrovoljno</em></p>

                <p>Korisnik može kroz prijavu za korišćenje servisa kono.bar i klopa.net dobrovoljno ostaviti sledeće podatke:</p>

                <ul>
                    <li>Ime i prezime</li>
                    <li>Mejl adresu</li>
                    <li>Telefon</li>
                    <li>Naziv firme</li>
                    <li>Adresu</li>
                    <li>Grad</li>
                    <li>Državu</li>
                </ul>

                <p>Korisnik takođe kroz registraciju kompanijskog naloga u okviru servis Kono.bar i Klopa.net dobrovoljno ostavlja sledeće podatke koji se odose na pravno lice:</p>

                <ul>
                    <li>naziv kompanije</li>
                    <li>tip kompanije (na primer restoran, bar, hotel)</li>
                    <li>matični broj</li>
                    <li>adresa</li>
                    <li>grad</li>
                    <li>ime kontakt osobe</li>
                    <li>mejl adresu</li>
                    <li>telefon</li>
                </ul>

                <p>a istovremeno korisnik kroz registraciju kompanijskog naloga dobrovoljno ostavlja sledeće podatke koji se odnose na fizičko lice odnosno ovlašćenog zastupnika pravnog lica:</p>

                <ul>
                    <li>ime i prezime</li>
                    <li>mejl adresu</li>
                    <li>pozciju (na primer vlasnik, menadžer)</li>
                </ul>

                <p>Neregistrovani korisnik kroz sajt klopa.net i servis kono.bar dobrovoljno ostavlja podatke u cilju vršenja porudžbine, a kako bi Korisnik mogao da pruži uslugu dostave ili preuzimanja hrane i pića, i to sledećih podataka:</p>

                <ul>
                    <li>ime</li>
                    <li>adresa</li>
                    <li>opština i</li>
                    <li>broj telefona</li>
                </ul>

                <p><em>Podaci o ličnosti koje prikupljamo o korisniku od trećih lica</em></p>

                <p>Korisnik nakon registracije kompanijskog naloga odnosno vlasnik kompanijskog naloga u okviru Servisa može registrovati dodatne korisnike odnosno svoje zaposlene (na primer menadžera, konobara, barmena, dostavljača) u kom slučaju rukovalac obrađuje sledeće podatke dobijene od registrovanog korisnika, vlasnika kompanijskog naloga:</p>

                <ul>
                    <li>ime i prezime</li>
                    <li>mejl adresu</li>
                    <li>radno mesto</li>
                </ul>

                <p><em>Podaci o ličnosti koje prikupljamo automatski</em></p>

                <p>Prilikom posete sajta kono.bar i sajta klopa.net, kao i prilikom korišćenja servisa Kono.bar, rukovalac automatski prikuplja pojedine podatke o ličnosti sa uređaja korisnika.</p>

                <p>Automatski prikupljene informacije mogu uključivati IP adresu, vrstu uređaja, jedinstveni identifikacijski broj uređaja, vrstu pretraživača i širu geografsku lokaciju. Istovremeno mogu biti prikupljene informacije o tome kako se uređaj korisnika ponaša na sajtu kono.bar, kao i informacije o stranicama kojima je korisnik pristupio i linkovima koje je kliknuo.</p>

                <p>Kompanija prikuplja i poslovne podatke pravnih lica u svrhu unapređenja servisa i generisanja anonimnih statističkih podataka servis Kono.bar, te navedene podatke neće koristiti u druge svrhe osim navedenih. Anonimni statistički podaci koji su nastali kroz korišćenje servisa Kono.bar su vlasništvo Kompanije.</p>

                <p>Prikupljene informacije nam omogućavaju da bolje razumemo korisnike koji dolaze na Sajtove i koji koriste Servise, kao i radi unapređenja Sajtova, Servisa i korisničkog iskustva odnosno radi unapređenja celokupnog sistema Kono.bar.</p>

                <p><em>Internet analitički podaci</em></p>

                <p>Kono.bar koristi Google analitiku i druge usluge internet analitike za sastavljanje analitičkih podataka i izveštaja o korišćenju Sajta i Servisa od strane posetilaca, kao i radi poboljšanja usluga Kono.bar.</p>

                <p>Ukoliko korisnik želi da izvrši pregled Google analitike to može učiniti posetom Google Analytics.</p>

                <p>Istovremeno ukazujemo da ukoliko korisnik ne želi da Google prikuplja i obrađuje korisnikove podatke generisane kroz upotrebu Sajta i Servisa, može isključiti Google analitiku instaliranjem alatke na pretraživaču: Google Analitycs opt-out Add-on.</p>


                <h4>Svrha obrade podataka i pravni osnov</h4>

                <p>Rukovalac obrađuje podatke o ličnosti u sledeće svrhe, u skladu sa navedenim pravnim osnovima:</p>

                <table>
                    <thead>
                    <tr>
                        <th>Svrha obrade</th>
                        <th>Pravni osnov</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>formiranja kompanijskog naloga</td>
                        <td>pristanak lica na koje se podaci odnose</td>
                    </tr>
                    <tr>
                        <td>pružanja usluga korišćenja servisa Kono.bar i Klopa.net</td>
                        <td>zaključenje i izvršenje ugovora i legitimni interes</td>
                    </tr>
                    <tr>
                        <td>Omogućavanje korisniku pružanja usluge dostave ili preuzimanja hrane i pića</td>
                        <td>pristanak lica na koje se podaci odnose, zaključenje i izvršenje ugovora i legitimni interes</td>
                    </tr>
                    <tr>
                        <td>unapređenja poslovanja korisnika</td>
                        <td>zaključenje i izvršenje ugovora i legitimni interes</td>
                    </tr>
                    <tr>
                        <td>poboljšanja kvaliteta usluga i analizu trendova</td>
                        <td>legitimni interes</td>
                    </tr>
                    <tr>
                        <td>sigurnosti i sprečavanja prevare</td>
                        <td>poštovanje pravnih obaveza i legitimni interes</td>
                    </tr>
                    </tbody>
                </table>

                <p>Prilikom obrade podataka na osnovu legitimnog interesa Kompanije, vodimo računa da obradu vršimo na način koji ne ugrožava prava i slobode korisnika. Kompanija prilikom obrade podataka iz legitimnih interesa vodi računa o balansu između ličnih interesa kompanije i prava na privatnost korisnika.</p>

                <p>Obrada podatak koja se vrši na osnovu pristanka lica za obradu podataka, podrazumeva da korisnik može povući pristanak u svakom trenutku.</p>


                <h4>Način korišćenja podataka</h4>

                <p>Podatke korisnika obrađujemo na osnovu izričite saglasnosti korisnika i u skladu sa drugim zakonom dozvoljenim razlozima.</p>

                <p>Podaci korisnika neće biti korišćeni u druge svrhe osim u one zbog kojih su prvobitno prikupljeni.</p>

                <p>Kompanija garantuje da neće zloupotrebiti, prodati niti proslediti trećim licima podatke registrovanih i neregistrovanih korisnika, niti poslovne podatke registrovanih pravnih lica.</p>

                <p>Kompanija ne skuplja i ne koristi podatke o ličnosti neregistrovanih korisnika Servisa niti podatke prosleđuje trećim licima, osim anonimne upotrebe podataka o IP adresama uređaja kojim se pristupa Servisu. Podatak o IP adresi neregistrovanog korisnika se koristi isključivo radi omogućavanja pristupa Servisu, sigurnosti i zaštiti od prevare.</p>

                <p>Kompanija ne skuplja i ne čuva podatke o ličnosti neregistrovanih korisnika Servisa koje se dostavljaju radi pružanja usluga dostave ili preuzimanja hrane i pića putem sajta klopa.net, niti podatke prosleđuje trećim licima, osim konkretnom registrovanom korisniku (na primer restoranu, baru i sl.) radi pružanja usluga dostave ili preuzimanja hrane i pića.</p>

                <p>Članovi našeg tima su obavezani čuvanjem poslovne tajne, a takođe su preduzete sve zakonske mere u cilju sprečavanja otkrivanja i odavanja informacija, osim u svrhe zbog kojih su prikupljeni.</p>


                <h4>Deljenje podataka</h4>

                <p>Kono.bar d.o.o. ne deli podatke korisnika trećim licima osim ukoliko poseduje saglasnost korisnika ili ukoliko postoji zakonska obaveza da budu podeljeni podaci korisnika sa npr. organima javne vlasti (tada nam nije potrebna saglasnot korisnika).</p>


                <h4>Vreme čuvanja podatka</h4>

                <p>Rukovalac čuva podatke u periodu neophodnom za ostvarenje svrhe zbog koje su prikupljeni odnosno u periodu dok postoji saglasnost korisnika.</p>

                <p>Ukoliko korisnik odluči da obriše korisnički nalog, Rukovalac se obavezuje da u roku od 100 dana obriše sve podatke koji se odnose na konkretnog korisnika</p>

                <p>Određeni podaci korisnika koji su navedeni u korisničkom nalogu mogu se čuvati onoliko koliko je za takvu obradu određeno zakonom ili koliko je zakonom potrebno za naše zakosnke obaveze, za vođenje postupka, mirno rešavanje sporova, knjigovodstvo.</p>

                <p>Analitičke podatke korisnika koji nemaju registrovani korisnički nalog na servisu Kono.bar čuvamo u trajanju od 365 dana.</p>


                <h4>Prava korisnika</h4>

                <p>Korisnik, kao lice čije podatke obrađujemo ima sledeća prava:</p>

                <ul>
                    <li>pravo na pristup</li>
                    <li>pravo na opoziv pristanka</li>
                    <li>pravo na ispravku i dopunu</li>
                    <li>pravo na brisanje</li>
                    <li>pravo na prigovor</li>
                    <li>pravo na ograničenje obrade</li>
                    <li>pravo na prenosivost podataka</li>
                    <li>pravo na pritužbu Povereniku za zaštitu informacija od javnog značaja</li>
                </ul>

                <p>Kompanija napominje korisniku da korišćenje prava na opoziv pristanka ne utiče na dopuštenost obrade podataka na osnovu pristanka u periodu pre opoziva.</p>

                <p>Navedena prava korisnik može ostvariti slanjem pisma na adresu rukovaoca ili slanjem elektronske pošte na mejl predstavnika za zaštitu podatka: <a href="mailto:data-privacy@kono.bar">data-privacy@kono.bar</a></p>

                <p>Prilikom obraćanja potrebno je da korisnik dostavi ime i prezime, adresu, elektronsku poštu i broj telefona, rukovalac zadržava pravo da zahteva dodatne informacije radi potvrde identite korisnika.</p>

                <p>Nadležni nadzorni organ za Kono.bar d.o.o. je Poverenik za informacije od javnog značaja, Beograd, ul. Bulevar Kralja Aleksandra br. 15, mejl: <a href="mailto:office@poverenik.rs">office@poverenik.rs</a>.</p>


                <h4>Bezbednost</h4>

                <p>Kompanija je preduzela sve organizacione, tehničke, fizičke i administrativne mere za zaštitu svih podataka koje prikuplja i obrađuje od svih vidova zloupotreba i krađe.</p>

                <p>Korisnik samostalno bira lozinku prilikom registracije i isključivo je odgovoran za tajnost lozinke i korišćenje servisa Kono.bar i Klopa.net putem pristupnih podataka. Korisnik neće koristiti tuđe pristupne podatke ili davati svoje korisničke podatke trećem licu. Korisnik može obavestiti Rukovaoca ukoliko posumnja na neovlašćeno korišćenje svojih pristupnih podataka.</p>

                <p>Rukovalac će u svakoj pojedinačnoj prilici pružiti punu podršku zaštiti podataka o ličnosti, pravima korisnika, privatnosti, svojinskih prava i prava intelektualne svojine svim licima nosiocima tih prava, ili onim licima čije je pravo povređeno, tako što će, bez odlaganja:</p>

                <ul>
                    <li>na obrazložen zahtev, potkrepljen odgovarajućom dokumentacijom, ukloniti sadržaj tako da ne bude javno dostupan, a sadržaj sačuvati za potrebe dokazivanja;</li>
                    <li>nadležnom organu, na zahtev dostaviti, podatke o korisniku čiji unos krši neko od navedenih ili drugih prava,</li>
                </ul>

                <p>sve u skladu sa važećom zakonskom regulativom Republike Srbije.</p>

                <p>Ukoliko pored preduzetih mera bude narušena bezbednost podataka korisnika, te ukoliko usled toga mogu nastati negativne posledice po privatnost korisnika, Kompanija će bez odlaganja o konkretnom narušavnju bezbednosti obavestiti korisnika i odgovarajuće organe javne vlasti kada je to neophodno u skladu sa važećom zakonskom regulativom Republike Srbije.</p>


                <h4>Izmene</h4>

                <p>Zadržavamo pravo da promenimo ili izmenimo važeću Politiku privatnosti Sajtova i Servisa.</p>

                <p>Korisnici će biti obavešteni da je Politika privatnosti izmenjena putem Sajtova, Servisa ili putem elektronske pošte registrovane od strane Korisnika.</p>

                <p>Ukoliko Korisnik ne prihvata izmene Politike privatnosti, Kompanija će obustaviti korišćenje Servisa za konkretnog Korisnika i zamoliti istog da prestane sa korišćenjem Sajtova.</p>

                <p><strong>Politika privatnosti je ažurirana dana 10.04.2020. godine i stupa na snagu danom objavljivanja.</strong></p>


                <h3>Pravilnik o kolačićima</h3>

                <p>Pravilnik o kolačićima zajedno sa Uslovima korišćenja i Politikom privatnosti kompanije Kono.bar d.o.o. Beograd (u daljem tekstu: Kompanija) predstavlja Ugovor koji se odnosi na korisnike.</p>

                <p>Kolačići su tekstualne datoteke sa podacima koje se čuvaju na uređajima korisnika. Kolačići omogućavaju da veb sajt kono.bar i veb sajt klopa.net (u daljem tekstu pojedinačno: Sajt, a zajedno: Sajtovi) i servis Kono.bar (u daljem tekstu: Servis) prepoznaju uređaj korisnika.</p>

                <p>Kolačići se koriste za prikupljanje podataka o upotrebi naših Sajtova i Servisa, te ne služe u svrhu prikupljanja podataka radi identifikacije korisnika Sajtova i Servisa, već radi poboljšanja sadržaja i unapređenja korisničkog iskustva.</p>

                <p>U zavisnosti od toga ko postavlja kolačiće, dele se na kolačiće prve strane i kolačiće treće strane. Kolačići koje postavi kompanija Kono.bar d.o.o. su kolačići prve strane. Kolačići koje postavi neki od servisa trećih lica koje Sajtovi i Servisi koriste da bi normalno funkcionisali se nazivaju kolačićima treće strane.</p>

                <p>Sajtovi i Servis koriste samo kolačiće prve strane i kolačiće treće strane potrebne za funkcionisanje sajtova i servisa, unapređenje bezbednosti i sprečavanje prevara.</p>

                <p>Vrste kolačića koji se koriste na Sajtovima i Servisima su:</p>

                <ul>
                    <li>Kolačići sesije koji se automatski brišu u roku od 24 sata</li>
                    <li>Funkcionalni kolačići neophodni za pravilno funkcionisanje sajta i servisa</li>
                    <li>Kolačići za personalizaciju koji pamte lične preferencije (na primer, prethodno izabrani jezik, što se koristi u svrhu unapređenja usluge koju Servis nudi)</li>
                    <li>Bezbednosni kolačići neophodni za zaštitu od napada i sprečavanje prevara</li>
                </ul>

                <p>Na stranicama koje koriste registrovani korisnici, lični podaci mogu biti sačuvani u kolačićima ukoliko se korisnik prethodno sa tim saglasi (na primer, čuvanje podataka za pristup kompanijskom nalogu).</p>

                <p>Ukoliko korisnik želi da onemogući korišćenje kolačića, to može učiniti putem odgovarajućeg menija u okviru internet pretraživača. Internet pretraživači su većinom konfigurisani na taj način da se podrazumeva prihvatanje kolačića, te ukoliko korisnik želi da odbije ili izbriše kolačiće i sličnu tehnologiju, to može učiniti na taj način što će pristupiti odeljku za pomoć i podršku u okviru internet pretraživača koji koristi, sa uputstvima kako da se izvrši blokiranje ili brisanje kolačića i slične tehnologije.</p>

                <p>Pravilnik o kolačićima može biti promenjen, izmenjen i ažuriran u određenim vremenskim periodima.</p>

                <p>Ukoliko dođe do promena, izmena ili ažuriranja Pravilnika o kolačićima korisnik će o tome biti obavešten putem Sajtova, Servisa i putem elektronske pošte registrovane od strane korisnika.</p>

                <p><strong>Pravilnik o kolačićima je ažuriran dana 10.04.2020. godine i stupa na snagu počev od dana objavljivanja.</strong></p>

            </div>

        </div>
    );
};

export default CookiePolicy;