import React, {Fragment} from 'react';
import {connect, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import InputNumber from 'rc-input-number';
import {generatePath, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Dotdotdot from "react-dotdotdot";

import {
	getCurrency,
	getMenuItem,
	getTableCode,
	getCategory,
	getOrdersDisabled,
	getUpsellItems,
	isCartDisabled,
} from "../reducers";
import {tryAddToCart} from "../actions/cart";
import {showInfoMessage} from "../actions/ui";
import {sendBrandArticleView, sendGroupBrandArticleView} from "../MenuAPI";

import Header from './Header';
import AddToCartButton from "./AddToCartButton";
import NotFound from './NotFound';
import {modifierHasPrice} from "../utils";


const setDefaultModifiers = (menuItem, modifierGroups) => {
	for (let group of menuItem.modifier_groups) {
		if (!modifierGroups.hasOwnProperty(group.id)) {
			if (group.group_type === 'single') {
				modifierGroups[group.id] = group.default_modifier;
			} else if (group.group_type === 'multi') {
				modifierGroups[group.id] = group.modifiers.reduce((acc, modifier) => {
					return {...acc, [modifier.id]: false};
				}, {})
			}
		}
	}
	return modifierGroups;
};

/**
 * Returns link that points to item category or main menu
 * if items category is not in location state.
 * @param table
 * @param location
 * @return {*|*}
 */
const getBackLink = (table, location) => {
	let backLink = generatePath('/:table/category', {table: table});
	if (location.state && location.state.fromCategory) {
		backLink = generatePath('/:table/meals/:categoryId', {table: table, categoryId: location.state.fromCategory});
	}
	return backLink;
};

/**
 * Convert new lines in string to BR tags.
 * @param text
 * @return {boolean|{text: undefined}}
 * @constructor
 */
function Nl2Br({text}) {
	return (
		<>
			{text && text.split('\n').map((item, key) =>
				<Fragment key={key}>{item}<br/></Fragment>
			)}
		</>
	);
}
Nl2Br.propTypes = {
	text: PropTypes.string,
};

function UpsellItem({itemId, currency, checked, onChange}) {
	const item = useSelector(state => getMenuItem(state, itemId));

	return (
		<span className="custom-check">
			<input
				type="checkbox"
				id={`upsell-${itemId}`}
				name={`upsell-${itemId}`}
				value={itemId}
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={`upsell-${itemId}`}>
				<span>
					<Dotdotdot clamp={1}>
						{item.name}
					</Dotdotdot>
					<em>{item.price} {currency}</em>
					{item.thumbnail &&
						<img src={item.thumbnail.medium} alt={item.name}/>
					}
				</span>
			</label>
		</span>
	);
}
UpsellItem.propTypes = {
	itemId: PropTypes.number.isRequired,
	currency: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

function setDefaultUpsells(menuItem, upsells) {
	if (menuItem.upsells) {
		return menuItem.upsells.reduce((acc, item) => (
			{
				...acc,
				[item]: upsells.hasOwnProperty(item) && upsells[item],
			}
			), {});
	}
	return {};
}

class Single extends React.Component {

	static propTypes = {
		match: PropTypes.object,
		currency: PropTypes.string,
		menuItem: PropTypes.object,
		tryAddToCart: PropTypes.func,
		showInfoMessage: PropTypes.func,
		table: PropTypes.string,
		category: PropTypes.object,
		ordersDisabled: PropTypes.bool,
		upsellItems: PropTypes.array,
	};

	state = {
		quantity: 1,
		comment: '',
		modifierGroups: {},
		upsells: {},
	};

	commentTimeout = 0;

	constructor(props) {
		super(props);

		this.commentRef = React.createRef();
	}

	static getDerivedStateFromProps(props, state) {
		const {menuItem} = props;
		const {modifierGroups, upsells} = state;

		if (menuItem) {
			return {
				modifierGroups: setDefaultModifiers(menuItem, modifierGroups),
				upsells: setDefaultUpsells(menuItem, upsells),
			};
		}
		return null;
	}

	componentDidMount() {
		// Send analytics data
		const {menuItem, table, upsellItems} = this.props;

		if (menuItem.brand_article) {
			sendBrandArticleView(table, menuItem.brand_article.id);
		}
		if (upsellItems.length) {
			sendGroupBrandArticleView(
				table,
				upsellItems.filter(item => !!item.brand_article).map(item => item.brand_article.id),
				'upsell'
			);
		}
	}

	componentWillUnmount() {
		// Clear focus timeout
		clearTimeout(this.commentTimeout);
	}

	onAddToCart = () => {
		const {
			menuItem,
			tryAddToCart,
			history,
			table,
			showInfoMessage,
			t,
			location,
			category,
			ordersDisabled,
			upsellItems,
		} = this.props;
		const {quantity, comment, modifierGroups, upsells} = this.state;

		// TODO: Try extracting some of the logic to action creator

		// Sum price of selected modifiers
		let modifiersPrice = .0;
		menuItem.modifier_groups.forEach(modGroup => {
			if (modifierGroups.hasOwnProperty(modGroup.id)) {
				if (modGroup.group_type === 'single') {
					const selectedModifier = modGroup.modifiers.find(mod => mod.id === modifierGroups[modGroup.id]);
					if (selectedModifier && modifierHasPrice(selectedModifier)) {
						modifiersPrice += parseFloat(selectedModifier.price);
					}
				} else {
					const modSum = modGroup.modifiers.reduce((acc, mod) => {
						return modifierGroups[modGroup.id][mod.id] && modifierHasPrice(mod) ? acc + parseFloat(mod.price) : acc;
					}, .0);
					modifiersPrice += modSum;
				}
			}
		});

		// Add item to cart
		if (!tryAddToCart({
			id: menuItem.id,
			price: (parseFloat(menuItem.price) + modifiersPrice).toFixed(2),
			quantity,
			comment,
			selectedModifiers: modifierGroups,
			categoryId: category ? category.id : null,
			categoryIcon: category ? category.icon : null,
		})) {
			return;
		}

		// Add upsells to cart here
		for (const upsellItem of upsellItems) {
			if (upsells[upsellItem.id]) {
				tryAddToCart({
					id: upsellItem.id,
					price: upsellItem.price,
					quantity,
					upsold_from: menuItem.id,
					selectedModifiers: {},
					categoryId: upsellItem.category_id,
					categoryIcon: upsellItem.category_icon,
				});
			}
		}

		showInfoMessage(ordersDisabled ? t('messages.addedToList') : t('messages.addedToCart'));

		// Reset selected options
		this.setState({
			quantity: 1,
			comment: '',
			modifierGroups: setDefaultModifiers(menuItem, {}),
		});

		// Go back to category or main menu
		history.push({pathname: getBackLink(table, location)});
		// history.push({pathname: generatePath('/:table/category', {table: table})});
	};

	onQuantityChange = (value) => {
		this.setState({quantity: value});
	};

	onCommentChange = (e) => {
		this.setState({comment: e.target.value});
	};

	onSingleModifierChange = (e, groupId) => {
		const {modifierGroups} = this.state;
		const target = e.target;

		this.setState({
			modifierGroups: {
				...modifierGroups,
				[groupId]: parseInt(target.value),
			}
		});
	};

	onMultiModifierChange = (e, groupId, id) => {
		const {modifierGroups} = this.state;
		const group = modifierGroups[groupId] || {};
		const target = e.target;

		this.setState({
			modifierGroups: {
				...modifierGroups,
				[groupId]: {
					...group,
					[target.value]: target.checked,
				}
			}
		});
	};

	onUpsellChange = e => {
		const {upsells} = this.state;
		const target = e.target;

		this.setState({
			upsells: {
				...upsells,
				[target.value]: target.checked,
			}
		});
	};

	onCommentFocus = (e) => {
		clearTimeout(this.commentTimeout);
		const cmRef = this.commentRef;
		this.commentTimeout = setTimeout(() => {
			if (cmRef.current.hasOwnProperty('scrollIntoViewIfNeeded')) {
				cmRef.current.scrollIntoViewIfNeeded();
			} else {
				cmRef.current.scrollIntoView();
			}
		}, 500);
	};

	onCommentBlur = (e) => {
		// Clear timeout on blur
		clearTimeout(this.commentTimeout);
	};

	render() {
		const {currency, menuItem, table, t, location, ordersDisabled, upsellItems, isCartDisabled} = this.props;
		const {quantity, comment, modifierGroups, upsells} = this.state;

		if (!menuItem) {
			return <NotFound/>;
		}

		const backLink = getBackLink(table, location);
		const hasUpsells = upsellItems && upsellItems.length > 0;

		return (
			<div className="app-inner single">
				<Header goBackTo={backLink}  backLabel={t('buttons.headerBack')}/>
				{menuItem.image &&
				<figure className="gallery">
					<img src={menuItem.image.medium} alt={menuItem.name} />
				</figure>
				}

				<div className="single-content">
					<h1>{menuItem.name}</h1>
					<h4>{menuItem.price} {currency}</h4>

					{/* Category info */}
					{menuItem.category_info.map(catInfo =>
						<p className="category-info" key={catInfo.text}><Nl2Br text={catInfo.text}/> </p>
					)}

					{/* Item description */}
					<p><Nl2Br text={menuItem.description}/></p>

					<ul className="form-fields mt-40">

						{/* Quantity */}
						{!isCartDisabled &&
							<li className="mb-40">
								<label>{t('messages.quantity')}</label>
								<InputNumber
									name="quantity"
									onChange={this.onQuantityChange}
									min={1}
									max={10}
									value={quantity}
									focusOnUpDown={false}
									precision={0}
								/>
							</li>
						}

						{/* Modifiers */}
						{menuItem.modifier_groups.map(group => {
							if (group.group_type === 'single') {
								return (
									<li key={group.id}>
										<label>{group.name}:</label>
										<div className="select-outer">
											<select
												value={modifierGroups[group.id]}
												onChange={(e) => this.onSingleModifierChange(e, group.id)}
											>
												{group.modifiers.map(modifier =>
													<option value={modifier.id} key={modifier.id}>
														{modifier.name}
														{modifierHasPrice(modifier) &&
															` - ${modifier.price} ${currency}`
														}
													</option>
												)}
											</select>
										</div>
									</li>
								);
							} else if (group.group_type === 'multi') {
								return (
									<li key={group.id} className="mb-40">
										<label>{group.name}:</label>
										{group.modifiers.map(modifier => (
											<span className="custom-check" key={modifier.id}>
												<input
													type="checkbox"
													id={`modifier-${modifier.id}`}
													name={`modifier-${modifier.id}`}
													value={modifier.id}
													checked={modifierGroups[group.id] && modifierGroups[group.id][modifier.id]}
													onChange={(e) => this.onMultiModifierChange(e, group.id, modifier.id)}
												/>
												<label htmlFor={`modifier-${modifier.id}`}>
													<span>{modifier.name}</span>
													{modifierHasPrice(modifier) &&
														<span> - {modifier.price}&nbsp;{currency}</span>
													}
												</label>
											</span>
										))}
									</li>
								);
							}
							return null;
						})}

						{/* Upsells */}
						{hasUpsells &&
							<li className="upsell-box">
								<label>{t('messages.upsellDrinks')}</label>

								{upsellItems.map(item =>
									<UpsellItem
										key={item.id}
										itemId={item.id}
										currency={currency}
										checked={upsells[item.id.toString()]}
										onChange={this.onUpsellChange}
									/>
								)}
							</li>
						}

						{/* Comment */}
						{!ordersDisabled &&
							<li>
								<label>{t('messages.additionalComments')}</label>
								<textarea
									ref={this.commentRef}
									id="comments"
									name="comments"
									value={comment}
									onChange={this.onCommentChange}
									onFocus={this.onCommentFocus}
									onBlur={this.onCommentBlur}
								/>
							</li>
						}

					</ul>
					
				</div>
				
				<AddToCartButton onAddToCart={this.onAddToCart}/>

			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const {match: {params: {id}}, location} = props;
	const menuItemId = parseInt(id);
	const menuItem = getMenuItem(state, menuItemId);

	let category = null;
	if (location.state && location.state.fromCategory) {
		category = getCategory(state, parseInt(location.state.fromCategory));
	}

	return {
		currency: getCurrency(state),
		table: getTableCode(state),
		ordersDisabled: getOrdersDisabled(state),
		upsellItems: getUpsellItems(state, menuItem.upsells),
		menuItem,
		category,
		isCartDisabled: isCartDisabled(state),
	}
};

export default withRouter(withTranslation()(connect(
	mapStateToProps,
	{
		tryAddToCart,
		showInfoMessage,
	}
)(Single)));