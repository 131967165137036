import React  from 'react';

const Spinner = () => {
    return (
        <div className="spinner-outer">
            <span className="spinner"></span>
        </div>
    );
};

export default Spinner;