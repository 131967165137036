
export const getSideMenuState = (state) => state.sideMenu.state;
export const getCurrentLanguage = (state) => state.languageSelector.currentLanguage;
export const getInfoMessage = (state) => state.infoMessage;
export const getConfirmationPopupVisible = (state) => state.confirmationPopupVisible;
export const getConfirmationPopupOrderId = (state) => state.confirmationPopupOrderId;
export const getCategoryScrollTop = (state) => state.categoryScrollTop;
export const getRequestConfirmationPopupVisible = (state) => state.requestConfirmationPopupVisible;
export const getPlaceOrderConfirmationPopupVisible = (state) => state.placeOrderConfirmationPopupVisible;
export const getMealsScrollTop = (state, categoryId) => {
    return state.mealsScrollTop[categoryId] || 0;
};
export const getSurveyPopupVisible = (state) => state.surveyPopupVisible;
