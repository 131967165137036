import React from 'react';
import PropTypes from 'prop-types';

function LinkButton(
	{
		onClick,
		children,
		className = '',
		enabled = true,
		href = '#button',
		...linkAttrs
	}) {
	const onLinkClick = (e) => {
		e.preventDefault();
		if (onClick && enabled) {
			onClick();
		}
	};

	return (
		<a
			href={href}
			className={`btn cta ${className}`}
			onClick={onLinkClick}
			{...linkAttrs}
		>
			{children}
		</a>
	);
};
LinkButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
	enabled: PropTypes.bool,
};

export default LinkButton;