import * as Sentry from "@sentry/browser";
import {createAction} from "@reduxjs/toolkit";

import {
    fetchSurveys as apiFetchSurveys,
    submitAnswer as apiSubmitSurvey,
    skipSurvey as apiSkipSurvey,
    viewedSurvey as apiViewedSurvey,
} from "../MenuAPI";
import {getGSID} from "../reducers/stateGetters";

export const fetchSurveyStarted = createAction('survey/fetchStarted');
export const fetchSurveySucceeded = createAction('survey/fetchSucceeded');
export const fetchSurveyError = createAction('survey/fetchError');

export const submitSurveyStarted = createAction('survey/submitSurveyStarted');
export const submitSurveySucceeded = createAction('survey/submitSurveySucceeded');
export const submitSurveyError = createAction('survey/submitSurveyError');

export const skipSurveyStarted = createAction('survey/skipSurveyStarted');
export const skipSurveySucceeded = createAction('survey/skipSurveySucceeded');
export const skipSurveyError = createAction('survey/skipSurveyError');

export const viewedSurveyStarted = createAction('survey/viewedSurveyStarted');
export const viewedSurveySucceeded = createAction('survey/viewedSurveySucceeded');
export const viewedSurveyError = createAction('survey/viewedSurveyError');

/**
 * Async action for fetching surveys,
 * @param tableCode
 * @return {function(*): Promise<AxiosResponse<*> | void>}
 */
export const fetchSurvey = (tableCode) => (dispatch) => {
    dispatch(fetchSurveyStarted());
    return apiFetchSurveys(tableCode).then(
        surveys => {
            dispatch(fetchSurveySucceeded(surveys));
            return surveys;
        },
        error => {
            Sentry.captureException(error);
            dispatch(fetchSurveyError(error.message));
            throw error;
        }
    )
};

export const submitSurvey = (tableCode, survey, answer=null) => (dispatch, getState) => {
    const gsid = getGSID(getState());
    dispatch(submitSurveyStarted());
    return apiSubmitSurvey(tableCode, survey, answer, gsid).then(
        surveyResult => {
            dispatch(submitSurveySucceeded(surveyResult));
            return surveyResult;
        },
        error => {
            Sentry.captureException(error);
            dispatch(submitSurveyError(error.message));
            throw error;
        }
    );
};

export const skipSurvey = (tableCode, survey) => (dispatch, getState) => {
    const gsid = getGSID(getState());
    dispatch(skipSurveyStarted());
    return apiSkipSurvey(tableCode, survey, gsid).then(
        result => {
            dispatch(skipSurveySucceeded(result));
            return result;
        },
        error => {
            Sentry.captureException(error);
            dispatch(skipSurveyError(error.message));
            throw error;
        }
    );
};

export const viewedSurvey = (tableCode, survey) => (dispatch, getState) => {
    const gsid = getGSID(getState());
    dispatch(viewedSurveyStarted());
    return apiViewedSurvey(tableCode, survey, gsid).then(
        result => {
            dispatch(viewedSurveySucceeded(result));
            return result;
        },
        error => {
            Sentry.captureException(error);
            dispatch(viewedSurveyError(error.message));
            throw error;
        }
    );
};
