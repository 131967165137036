import React from 'react';
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";


function processSvg(src) {
	const processedStr = src
		.replace(/<\s*style[^>]*>([\s\S]*?)<\s*\/\s*style>/g, '<style type="text/css">.st0{display: none}</style>');
	return processedStr;
}

/**
 * Returns first available image path. Uses preferred size if set and available.
 * @param thumbnail
 * @param preferredSize
 * @return {null|String}
 */
function getThumbnail(thumbnail, preferredSize = null) {
	if (preferredSize && thumbnail.hasOwnProperty(preferredSize)) {
		return thumbnail[preferredSize];
	}

	for (const size of ['600', '300', '120', 'medium', 'small']) {
		if (thumbnail.hasOwnProperty(size)) {
			return thumbnail[size];
		}
	}
	return null;
}

function Thumbnail(
	{
		thumbnail = null,
		icon = null,
		name = '',
		onClick = null,
		isCombo = false,
		isPromo = false,
		preferredSize = null,
	})
{
	if (thumbnail) {
		if (onClick) {
			return (
				<figure>
					<a href="#open" onClick={onClick}>
						<img src={thumbnail.small} alt={name}/>
					</a>
				</figure>
			);
		} else {
			return (
				<figure>
					<img src={isCombo ? thumbnail['300'] : getThumbnail(thumbnail, preferredSize)} alt={name}/>
				</figure>
			);
		}
	}
	if (icon) {
		return (
			<figure onClick={onClick} className="default">
				<SVG
					src={icon.replace('http://', '//')}
					className="list-icon"
					onError={() => console.error('error loading svg')}
					preProcessor={isPromo ? null : processSvg}
				/>
			</figure>
		);
	}
	return <p style={{paddingRight: "10px"}}></p>;
}

Thumbnail.propTypes = {
	cartItem: PropTypes.any,
	onClick: PropTypes.func,
	thumbnail: PropTypes.any,
	icon: PropTypes.string,
	name: PropTypes.string,
	isCombo: PropTypes.bool,
	preferredSize: PropTypes.string,
};

export default Thumbnail;