import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import 'react-flags-select/scss/react-flags-select.scss';

import {requestChangeLanguage} from "../actions/ui";
import {fetchMenu} from "../actions/menu";
import {fetchSurvey} from "../actions/surveys";
import {getCurrentLanguage, getTableCode} from "../reducers";

const LANGUAGE_CODE_COUNTRY_MAP = {
	'en': 'US',
	'es': 'ES',
	'zh': 'CN',
	'ru': 'RU',
	'de': 'DE',
	'fr': 'FR',
	'it': 'IT',
	'sr-latn': 'RS_LATN',
	'sr-cyrl': 'RS',
	'el': 'GR',
	'tr': 'TR',
	'sv': 'SE',
	'ro': 'RO',
	'bs': 'BA',
	'hr': 'HR',
	'hu': 'HU',
	'sr-latn-me': 'ME',
};
const COUNTRY_LANGUAGE_CODE_MAP = {
	'US': 'en',
	'ES': 'es',
	'CN': 'zh',
	'RU': 'ru',
	'DE': 'de',
	'FR': 'fr',
	'IT': 'it',
	'RS_LATN': 'sr-latn',
	'RS': 'sr-cyrl',
	'GR': 'el',
	'TR': 'tr',
	'SE': 'sv',
	'RO': 'ro',
	'BA': 'bs',
	'HR': 'hr',
	'HU': 'hu',
	'ME': 'sr-latn-me',
};

class LanguageSelector extends React.Component {

	static propTypes = {
		countries: PropTypes.arrayOf(PropTypes.object).isRequired,
		selectedLanguage: PropTypes.string,
		requestChangeLanguage: PropTypes.func,
		fetchMenu: PropTypes.func,
		table: PropTypes.string,
		fetchSurvey: PropTypes.func,
	};

	onSelect = (e) => {
		const {requestChangeLanguage, fetchMenu, table, fetchSurvey} = this.props;
		const languageCode = COUNTRY_LANGUAGE_CODE_MAP[e];
		requestChangeLanguage(languageCode);
		fetchMenu(table);
		fetchSurvey(table);
	};

	render() {
		const {countries, selectedLanguage} = this.props;
		const langCountriesMap = countries.map(item => LANGUAGE_CODE_COUNTRY_MAP[item.code]);

		const selectedCountry = LANGUAGE_CODE_COUNTRY_MAP[selectedLanguage];

		return (
			<div className="flag-select-outer">
				<ReactFlagsSelect
					countries={langCountriesMap}
					customLabels={{
						"US": "English",
						"RS_LATN": "Srpski",
						"RS": "Српски",
						"DE": "Deutsch",
						"IT": "Italiano",
						"FR": "Français",
						"ES": "Español",
						"RU": "Русский",
						"CN": "中文",
						"GR": "Ελληνικά",
						"TR": "Türkçe",
						"SE": "Svenska",
						"RO": "Română",
						"BA": "Bosanski",
						"HR": "Hrvatski",
						"HU": "Magyar",
					}}
					defaultCountry={selectedCountry}
					onSelect={this.onSelect}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedLanguage: getCurrentLanguage(state),
		table: getTableCode(state),
	};
};

export default withTranslation()(connect(mapStateToProps, {
	requestChangeLanguage,
	fetchMenu,
	fetchSurvey,
})(LanguageSelector));