import React, {useState, useEffect} from 'react';
import * as Sentry from "@sentry/browser";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {generatePath, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import {isMobileOnly} from "react-device-detect";
import ReactGA from 'react-ga';

import Header from './Header';
import CartItem from './CartItem';
import BackToMainMenuButton from './BackToMainMenuButton';
import LinkButton from "./LinkButton";

import IconCheck from './icons/IconCheck';
import IconClock from './icons/IconClock';
import IconTrash from './icons/IconTrash';
import IconCall from './icons/IconCall';
import IconBell from "./icons/IconBell";


import {
	getCartItems,
	getCartTotal,
	getCurrency,
	getMenuItem,
	getTableCode,
	getLastOrderId,
	getOrderStatus,
	getOrderIsSending,
	getOrderError,
	getOrderStatusError,
	getPreviousOrders,
	getOrdersDisabled,
	getRestaurantDefaultLanguage,
	getCurrentLanguage,
	getDeliveryPhone,
	isDeliveryEnabled,
	isDeliveryAvailable,
	isAcceptingSms,
	getRestaurant,
	canUseOrdering,
	canUseWaiterCall,
	getWaiterRequestIsSending,
	getWaiterRequestError,
	canSendPaymentTypeWithOrder,
	canUsePaymentTypeCard,
	canUsePaymentTypeRoom,
} from "../reducers";
import {getMenuItem as getLocalMenuItem} from '../reducers/menuGetters';
import {
	removeFromCart,
	updateItemQuantity,
	createNewOrder,
	clearCart,
	sendOrder,
	sendRequest,
} from '../actions/cart';
import {
	showPlaceOrderConfirmationPopup,
	displayThankYouMessage,
} from "../actions/ui";
import {
	fetchMenu,
} from "../MenuAPI";
import {getPreparedItems} from "../utils";
import {BillPaymentSelector} from "./BillPaymentSelector";

/**
 * Popup for selecting payment method.
 * @param onPaymentSelected
 * @param canUsePaymentTypeCard
 * @param canUsePaymentTypeRoom
 * @return {JSX.Element}
 * @constructor
 */
function SelectPaymentPopup(
	{
		onPaymentSelected,
		canUsePaymentTypeCard = true,
		canUsePaymentTypeRoom = false
	}) {
	return (
		<div className="active">
			<div className="background-overlay"></div>

			<div className="side-menu">
				<div className="side-menu-content">
					<BillPaymentSelector
						onPaymentSelected={onPaymentSelected}
						canUsePaymentTypeRoom={canUsePaymentTypeRoom}
						canUsePaymentTypeCard={canUsePaymentTypeCard}
						canUseBillForCompany={false}
					/>
				</div>
			</div>
		</div>
	);
}
SelectPaymentPopup.propTypes = {
	onPaymentSelected: PropTypes.func,
	canUsePaymentTypeCard: PropTypes.bool,
	canUsePaymentTypeRoom: PropTypes.bool,
};

function CartAction(
	{
		orderIsSending,
		orderStatus,
		ordersDisabled,
		currentLanguage,
		defaultLanguage,
		onTranslate,
		onSendOrder,
		onMakeDeliveryOrder,
		onMakeNewOrder,
		loading,
		deliveryPhone,
		isDeliveryAvailable,
		isDeliveryEnabled,
		isAcceptingSms,
		restaurantName,
		canUseOrdering,
		canUseWaiterCall,
		onCallWaiter,
		waiterRequestIsSending,
	}
	) {

	const [t] = useTranslation();

	if (orderIsSending || waiterRequestIsSending) {
		return (
			<a href="#order" className="btn medium" onClick={e => e.preventDefault()}>
				<IconCheck/>{t('messages.orderSending')}
			</a>
		);
	}

	// Register phone order with GA
	const onPhoneClick = () => {
        const linkHref = `tel:${deliveryPhone}`;

        const hitCallback = () => {
            if (!safetyCallbackCalled) {
                clearTimeout(safetyTimeout);
            	window.location.href = linkHref;
            }
        };

        ReactGA.ga('send', 'event', {
            eventCategory: 'Call restaurant',
            eventAction: 'Call - Cart',
            eventLabel: restaurantName,
            hitCallback: hitCallback,
        });

        let safetyCallbackCalled = false;
        const safetyCallback = () => {
            hitCallback();
            safetyCallbackCalled = true;
        };
        const safetyTimeout = setTimeout(safetyCallback, 250);
    };

	// TODO: Check if this status checking is still necessary
	if (orderStatus === "new") {
		if (isDeliveryEnabled) {
			// Case for delivery codes
			if ((ordersDisabled && !isAcceptingSms)) {
				return (
					<LinkButton
						href={`tel:${deliveryPhone}`}
						className="medium"
						onClick={onPhoneClick}
					>
						<IconCall/>
						<span className="call-label">{t('buttons.callRestaurant')}</span>
						<span className="phone-number">{deliveryPhone}</span>
					</LinkButton>
				);
			} else {
				return (
					<LinkButton href="#order" className="medium" onClick={onMakeDeliveryOrder}>
						<IconCheck/>{t('buttons.nextStep')}
					</LinkButton>
				);
			}
		} else if (!canUseOrdering && !canUseWaiterCall) {
			// This should be case for menu or when both can order and can call waiter is disabled
			if (currentLanguage !== defaultLanguage) {
				return (
					<LinkButton href="#translate"
					   className={classNames(["medium"], {
						   loading: loading,
					   })}
					   onClick={onTranslate}
					>
						{loading && <span className="spinner"></span>}
						{t('buttons.translateList')}
					</LinkButton>
				);
			} else {
				return null;
			}
		} else if (!canUseOrdering && canUseWaiterCall) {
			return (
				<LinkButton href="#call-waiter" className="medium" onClick={onCallWaiter}>
					<IconBell/>{t('buttons.callWaiter')}
				</LinkButton>
			);
		} else {
			return (
				<LinkButton href="#order" className="medium" onClick={onSendOrder}>
					<IconCheck/>{t('buttons.order')}
				</LinkButton>
			);
		}
	} else {
		return (
			<LinkButton href="#neworder" className="medium" onClick={onMakeNewOrder}>
				<IconCheck/>{t('buttons.newOrder')}
			</LinkButton>
		);
	}
}

CartAction.propTypes = {
	orderIsSending: PropTypes.any,
	orderStatus: PropTypes.any,
	ordersDisabled: PropTypes.any,
	currentLanguage: PropTypes.any,
	defaultLanguage: PropTypes.any,
	loading: PropTypes.bool,
	onTranslate: PropTypes.func,
	onSendOrder: PropTypes.func,
	onMakeDeliveryOrder: PropTypes.func,
	onMakeNewOrder: PropTypes.func,
	deliveryPhone: PropTypes.string,
	isDeliveryAvailable: PropTypes.bool,
	isDeliveryEnabled: PropTypes.bool,
	isAcceptingSms: PropTypes.bool,
	restaurantName: PropTypes.string.isRequired,
	canUseWaiterCall: PropTypes.bool,
	canUseOrdering: PropTypes.bool,
	onCallWaiter: PropTypes.func,
	waiterRequestIsSending: PropTypes.bool,
};

function Cart(
	{
		table,
		cartItems,
		cartTotal,
		currency,
		orderStatus,
		lastOrderId,
		orderIsSending,
		orderError,
		orderStatusError,
		previousOrders,
		ordersDisabled,
		removeFromCart,
		updateItemQuantity,
		showPlaceOrderConfirmationPopup,
		createNewOrder,
		clearCart,
		defaultLanguage,
		currentLanguage,
		deliveryPhone,
		isDeliveryAvailable,
		isDeliveryEnabled,
		isAcceptingSms,
		restaurant,
		sendOrder,
		canUseOrdering,
		canUseWaiterCall,
		sendRequest,
		displayThankYouMessage,
		waiterRequestIsSending,
		showWaiterRequestError,
		canSendPaymentTypeWithOrder,
		canUsePaymentTypeCard,
		canUsePaymentTypeRoom,
	}
) {

	const [t] = useTranslation();

	const [stateCartItems, setStateCartItems] = useState(cartItems);
	const [menuLoading, setMenuLoading] = useState(false);
	const [cartTranslated, setCartTranslated] = useState(false);
	const [translateError, setTranslateError] = useState(false);
	const [showPaymentSelection, setShowPaymentSelection] = useState(false);

	useEffect(() => {
		setStateCartItems(cartItems);
		setCartTranslated(false);
	}, [cartItems]);

	const onDeleteItem = (ind) => {
		removeFromCart(ind);
	};

	const onUpdateQuantity = (ind, value) => {
		updateItemQuantity({itemInd: ind, quantity: value});
	};

	const onSendOrder = () => {
		// Check if we need to select payment type before submitting order
		if (canSendPaymentTypeWithOrder) {
			setShowPaymentSelection(true);
			return;
		}

		const preparedItems = getPreparedItems(cartItems);

		sendOrder(table, preparedItems, {});
	};

	// Send order when payment type is selected
	const onPaymentSelected = (paymentType) => {
		const preparedItems = getPreparedItems(cartItems);
		sendOrder(table, preparedItems, {}, paymentType);
		setShowPaymentSelection(false);
	};

	const onCallWaiter = () => {
		sendRequest(table, 'call');
		displayThankYouMessage();
	};

	const onEmptyList = () => {
		clearCart();
		setStateCartItems([]);
	};
	const onTranslate = () => {
		if (menuLoading) return;

		setTranslateError(false);

		if (cartTranslated) {
			setStateCartItems(cartItems);
			setCartTranslated(false);
			return;
		}

		setMenuLoading(true);

		fetchMenu(table, {headers: {'Accept-Language': defaultLanguage}})
			.then(
				categories => {
					setMenuLoading(false);
					const defaultMenu = {data: categories};
					const translatedItems = cartItems.map(item => ({
						...item,
						...getLocalMenuItem(defaultMenu, item.id)
					}));
					setStateCartItems(translatedItems);
					setCartTranslated(true);
				},
				error => {
					setMenuLoading(false);
					setTranslateError(true);
					Sentry.captureException(error);
				}
		);
	};

	let pageTitle = '';
	if (ordersDisabled) {
		pageTitle = t('messages.yourList');
	} else if (orderStatus === 'new') {
		t('messages.yourCart');
	} else {
		t('messages.orderNo', {lastOrderId});
	}
	const categoryLink = table !== '' ? generatePath('/:table/category', {table}) : '';

	const appInnerClasses = classNames({
		'app-inner': true,
		'cart': true,
		'has-error': (orderError || orderStatusError || showWaiterRequestError),
	});
	return (
		<div className={appInnerClasses}>
			<Header
				pageTitle={pageTitle}
				goBackTo={categoryLink}
				backLabel={t('buttons.headerBackToMain')}
			/>

			{(orderError || orderStatusError || translateError || showWaiterRequestError) &&
				<div className="inline-error">
					<p>{t('messages.connectionError')}</p>
				</div>
			}

			{showPaymentSelection &&
				<SelectPaymentPopup
					onPaymentSelected={onPaymentSelected}
					canUsePaymentTypeRoom={canUsePaymentTypeRoom}
					canUsePaymentTypeCard={canUsePaymentTypeCard}
				/>
			}

			{cartItems.length === 0 &&
				<h1>{ordersDisabled ? t('messages.listIsEmpty') : t('messages.cartIsEmpty')}</h1>
			}
			{orderStatus !== 'new' &&
				<div className="status-bar">
					<p>Your order status: <span><IconClock /> {orderStatus}</span></p>
				</div>
			}

			<div className="article-list">
				{stateCartItems.map((item, i) =>
					<CartItem
						key={item.orderItemId}
						cartItem={item}
						currency={currency}
						editable={orderStatus === 'new'}
						onDelete={() => onDeleteItem(i)}
						onUpdateQuantity={val => onUpdateQuantity(i, val)}
					/>
				)}

			</div>

			<BackToMainMenuButton
				to={categoryLink}
				label={t('buttons.backToMainMenu')}
				noBottomSpacing={true}
			/>

			{cartItems.length > 0 &&
				<div className="inline-button-holder frame space-bottom">
					<LinkButton
						href="#clear"
						className="btn large inline flat"
						onClick={onEmptyList}
					>
						<IconTrash/>
						{t('buttons.emptyList')}
					</LinkButton>
				</div>
			}

			{previousOrders.length > 0 && !ordersDisabled &&
				<div
					className="inline-button-holder frame"
				>
					<Link
						to={generatePath('/:table/previousOrders', {table})}
						className="btn large inline flat"
					>
						{t('buttons.previousOrders')}
					</Link>
				</div>
			}

			{(cartItems.length > 0 || (isDeliveryEnabled && isAcceptingSms && cartItems.length > 0) || (isDeliveryEnabled && (!isAcceptingSms || !isMobileOnly))) &&
			<div className="cart-info bottom">
				<div className="left-info">
					<h3><span>{t('messages.total')}</span> {cartTotal} {currency}</h3>
				</div>
				<div className="right-info">
					<CartAction
						orderIsSending={orderIsSending}
						orderStatus={orderStatus}
						ordersDisabled={ordersDisabled}
						currentLanguage={currentLanguage}
						defaultLanguage={defaultLanguage}
						loading={menuLoading}
						deliveryPhone={deliveryPhone}
						isDeliveryAvailable={isDeliveryAvailable}
						isDeliveryEnabled={isDeliveryEnabled}
						isAcceptingSms={isAcceptingSms}
						restaurantName={restaurant.name}
						canUseOrdering={canUseOrdering}
						canUseWaiterCall={canUseWaiterCall}
						waiterRequestIsSending={waiterRequestIsSending}
						onTranslate={onTranslate}
						onSendOrder={onSendOrder}
						onMakeDeliveryOrder={showPlaceOrderConfirmationPopup}
						onMakeNewOrder={createNewOrder}
						onCallWaiter={onCallWaiter}
					/>
				</div>
			</div>
			}

		</div>
	);
}

Cart.propTypes = {
	cartItems: PropTypes.array,
	cartTotal: PropTypes.string,
	currency: PropTypes.string,
	table: PropTypes.string,
	removeFromCart: PropTypes.func,
	updateItemQuantity: PropTypes.func,
	lastOrderId: PropTypes.string,
	orderStatus: PropTypes.string,
	createNewOrder: PropTypes.func,
	orderIsSending: PropTypes.bool,
	orderError: PropTypes.string,
	orderStatusError: PropTypes.string,
	previousOrders: PropTypes.array,
	ordersDisabled: PropTypes.bool,
	clearCart: PropTypes.func,
	showPlaceOrderConfirmationPopup: PropTypes.func,
	defaultLanguage: PropTypes.string,
	currentLanguage: PropTypes.string,
	deliveryPhone: PropTypes.string,
	isDeliveryAvailable: PropTypes.bool,
	isDeliveryEnabled: PropTypes.bool,
	isAcceptingSms: PropTypes.bool,
	restaurant: PropTypes.object,
	sendOrder: PropTypes.func,
	canUseOrdering: PropTypes.bool,
	canUseWaiterCall: PropTypes.bool,
	sendRequest: PropTypes.func,
	displayThankYouMessage: PropTypes.func,
	waiterRequestIsSending: PropTypes.bool,
	waiterRequestError: PropTypes.string,
	showWaiterRequestError: PropTypes.bool,
	canSendPaymentTypeWithOrder: PropTypes.bool,
	canUsePaymentTypeRoom: PropTypes.bool,
	canUsePaymentTypeCard: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const cartItems = getCartItems(state).map(item => ({
		...getMenuItem(state, item.id),
		...item
	}));

	const showWaiterRequestError = (getWaiterRequestError(state) && !canUseOrdering(state) && canUseWaiterCall(state));

	return {
		cartItems: cartItems,
		cartTotal: getCartTotal(state).toFixed(2),
		currency: getCurrency(state),
		table: getTableCode(state),
		lastOrderId: getLastOrderId(state),
		orderStatus: getOrderStatus(state),
		orderIsSending: getOrderIsSending(state),
		orderError: getOrderError(state),
        orderStatusError: getOrderStatusError(state),
		previousOrders: getPreviousOrders(state),
		ordersDisabled: getOrdersDisabled(state),
		defaultLanguage: getRestaurantDefaultLanguage(state).code,
		currentLanguage: getCurrentLanguage(state),
		deliveryPhone: getDeliveryPhone(state),
		isDeliveryAvailable: isDeliveryAvailable(state),
		isDeliveryEnabled: isDeliveryEnabled(state),
		isAcceptingSms: isAcceptingSms(state),
		restaurant: getRestaurant(state),
		canUseWaiterCall: canUseWaiterCall(state),
		canUseOrdering: canUseOrdering(state),
		waiterRequestIsSending: getWaiterRequestIsSending(state),
		waiterRequestError: getWaiterRequestError(state),
		showWaiterRequestError,
		canSendPaymentTypeWithOrder: canSendPaymentTypeWithOrder(state),
		canUsePaymentTypeCard: canUsePaymentTypeCard(state),
		canUsePaymentTypeRoom: canUsePaymentTypeRoom(state),
	};
};

export default connect(
	mapStateToProps,
	{
		removeFromCart,
		updateItemQuantity,
		createNewOrder,
		clearCart,
		showPlaceOrderConfirmationPopup,
		sendOrder,
		sendRequest,
		displayThankYouMessage,
	}
)(Cart);