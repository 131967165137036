import {createAction} from "@reduxjs/toolkit";

export const timerStarted = createAction('timer/started');
export const timerStopped = createAction('timer/stopped');
export const timerTick = createAction('timer/tick');

let intervalId = null;

export const startTimer = (interval = 1000) => (dispatch) => {
    window.clearInterval(intervalId);
    intervalId = window.setInterval(() => {
        dispatch(timerTick());
    }, interval);
    dispatch(timerStarted());
    dispatch(timerTick());
};

export const stopTimer = () => (dispatch) => {
    window.clearInterval(intervalId);
    dispatch(timerStopped());
};