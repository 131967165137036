import {timeToDate} from "../utils";
import React from "react";

const WorkingHours = ({workingHours, t}) => {
    if (!workingHours) {
        return <p>{t('messages.closedForDelivery')}</p>
    }

    const openTime = timeToDate(workingHours.open_time);
    const closeTime = timeToDate(workingHours.close_time);
    const openTimeFmt = `${openTime.getHours().toString().padStart(2, '0')}:${openTime.getMinutes().toString().padStart(2, '0')}`;
    const closeTimeFmt = `${closeTime.getHours().toString().padStart(2, '0')}:${closeTime.getMinutes().toString().padStart(2, '0')}`;
    return (
        <p className="working-hours">{t('messages.workingHours')}: {openTimeFmt}&nbsp;-&nbsp;{closeTimeFmt}</p>
    );
};

export default WorkingHours;