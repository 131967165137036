import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import {displayCallWaiterWithoutLanguageOption} from "../actions/ui";

import {useTranslation} from "react-i18next";

import {
    getOrdersDisabled,
    isDeliveryEnabled,
    canUseWaiterCall,
    canUseRequestBill,
} from '../reducers';


const CallWaiterButton = (
    {
        displayCallWaiterWithoutLanguageOption,
        location,
        history,
        ordersDisabled,
        isDeliveryEnabled,
        canUserWaiterCall,
        canUseRequestBill,
    }) => {

    const [t] = useTranslation();

    // Is any option allowed?
    if (!canUserWaiterCall && !canUseRequestBill) {
        return null;
    }

    const callWaiter = (e) => {
        e.preventDefault();
        displayCallWaiterWithoutLanguageOption();
        history.push({...location, hash: '#side-menu', state: {fromCallWaiter: true}});

    };

    // Construct button label
    let buttonLabels = [];
    if (canUseRequestBill) {
        buttonLabels.push(t('buttons.waiterRequestBill'));
    }
    if (canUserWaiterCall) {
        buttonLabels.push(t('buttons.waiter'));
    }

    return (
        <div className="floating-container">
            <a
                href="#side-menu"
                onClick={callWaiter}
                className="btn large floating"
            >{buttonLabels.join(' / ')}</a>
        </div>
    );
};

CallWaiterButton.propTypes = {
    displayThankYouMessage: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    isDeliveryEnabled: PropTypes.bool,
    canUserWaiterCall: PropTypes.bool,
    canUseRequestBill: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        ordersDisabled: getOrdersDisabled(state),
        isDeliveryEnabled: isDeliveryEnabled(state),
        canUserWaiterCall: canUseWaiterCall(state),
        canUseRequestBill: canUseRequestBill(state),
    };
};

export default withRouter(connect(mapStateToProps, {
    displayCallWaiterWithoutLanguageOption
})(CallWaiterButton));