import {createSelector} from "@reduxjs/toolkit";

export const getOrder = (state, orderId) => {
    if (state.currentOrder.orderId === orderId) {
        return state.currentOrder;
    } else {
        const result = getPreviousOrders(state).find((order) => {
            return order.orderId === orderId;
        });
        return result;
    }
};

export const getLastOrderId = state => state.currentOrder.orderId;
export const getOrderStatus = state => state.currentOrder.status;
export const getOrderStatusError = state => state.currentOrder.statusError;
export const getDeliveryTime = state => state.currentOrder.deliveryTime;
export const getLastOrderType = state => state.currentOrder.type;

export const getCartItems = state => state.currentOrder.cartItems;
export const getCartTotal = createSelector(
    [getCartItems],
    items => items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
);

export const getOrderIsSending = state => state.currentOrder.orderIsSending;
export const getOrderError = state => state.currentOrder.orderSendError;

export const getOrderCreatedAt = state => state.currentOrder.createdAt;
export const getPreviousOrders = state => state.previousOrders;

export const getWaiterRequestId = state => state.waiterRequest.requestId;
export const getWaiterRequestIsSending = state => state.waiterRequest.requestIsSending;
export const getWaiterRequestError = state => state.waiterRequest.error;
export const getWaiterRequestIsCompleted = state => state.waiterRequest.isCompleted;