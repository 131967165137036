import {createReducer} from "@reduxjs/toolkit";

import {updateDeliveryData} from "../actions/deliveryData";

const deliveryData = createReducer(
    {
        guest_name: '',
        guest_phone: '',
        guest_municipality: '',
        guest_address: '',
    },
    {
        [updateDeliveryData]: (state, action) => {
            return action.payload;
        },
    }
);

export default deliveryData;