import i18n from 'i18next';
import backend from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";


i18n
    .use(initReactI18next)
    .use(backend)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false
        },
        backend: {
            queryStringParams: { v: process.env.REACT_APP_VERSION }
        },
        react: {
            useSuspense: false,
        },
});

export default i18n;
