import {createReducer} from '@reduxjs/toolkit';

import {
    fetchRestaurantStarted,
    fetchRestaurantSucceeded,
    fetchRestaurantError,
    setSelectedBanner,
} from '../actions/restaurant';


export default createReducer(
    {
        loading: false,
        data: undefined,
        error: undefined,
        previousRestaurantId: null,
        selectedBanner: null,
    },
    {
        [fetchRestaurantStarted]: (state) => {
            state.loading = true;
            state.error = undefined;
        },
        [fetchRestaurantSucceeded]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.previousRestaurantId = action.payload.id;
        },
        [fetchRestaurantError]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [setSelectedBanner]: (state, action) => {
            state.selectedBanner = action.payload;
        }
    }
);
