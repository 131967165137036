import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";

import Header from './Header';
import CartInfo from './CartInfo';
import CallWaiterButton from './CallWaiterButton';
import {
    getCategory,
    getMenuItems,
    getCurrency,
    getTableCode,
	getMealsScrollTop,
	isDeliveryEnabled,
	getRestaurant,
	getDeliveryPhone,
	isCartDisabled,
} from '../reducers';
import {updateMealsScrollTop} from '../actions/ui';
// import useLastScrollDirection from '../hooks/useLastScrollDirection';
import BackToMainMenuButton from './BackToMainMenuButton';
import BottomBanner from './BottomBanner';
import MenuItem from './MenuItem';
import {DeliveryPhoneLink} from "./DeliveryPhoneLink";
import {sendGroupBrandArticleView} from "../MenuAPI";


/**
 * Pass through component just returns it's children.
 * It's used to add key prop when array map needs to return more than one child component.
 * @param children
 * @return {*}
 * @constructor
 */
function PassThrough({children}) {
    return (children);
}

function Meals(
	{
		meals,
		selectedCategory,
		currency,
		table,
		categoryId,
		updateMealsScrollTop,
		oldScrollTop,
		isDeliveryEnabled,
		restaurant,
		deliveryPhone,
		isCartDisabled,
	}) {

	const [t] = useTranslation();

	const containerRef = useRef();
	// const lastScrollDirection = useLastScrollDirection(containerRef, 'up');
	const lastScrollDirection = 'up';

	// Persist meals scroll position
	useLayoutEffect(() => {
		let containerNode = containerRef.current;
		containerNode.scrollTop = oldScrollTop;

		return () => {
			updateMealsScrollTop({categoryId, scroll: containerNode.scrollTop});
		};
	}, [categoryId, updateMealsScrollTop, oldScrollTop]);

	// Send analytics data
	useEffect(() => {
		const ids = meals
			.filter(meal => meal.brand_article)
			.map(meal => meal.brand_article.id);
		if (ids.length) {
			sendGroupBrandArticleView(table, ids, 'category');
		}
	}, [table, meals]);

	const categoryName = selectedCategory ? selectedCategory.name : '';
	const pathToMainMenu = table !== '' ? generatePath('/:table/category', {table: table}) : '';

	const listClasses = classNames({
		'app-inner': true,
		'meals-and-category-list': true,
		'no-cart': isCartDisabled,
		[`scrolling-${lastScrollDirection}`]: true,
	});

	let subcategory = null;

	return (
		<div className={listClasses} ref={containerRef}>

			<Header pageTitle={categoryName} goBackTo={pathToMainMenu} backLabel={t('buttons.headerBackToMain')}/>
			<CartInfo/>
			<div className="article-list">

				{isDeliveryEnabled &&
					<p className="delivery-phone">
						{t('messages.deliveryPhone')}:{' '}
						<DeliveryPhoneLink
							phone={deliveryPhone}
							restaurantName={restaurant.name}
							componentLocation={'Category'}
						/>
					</p>
				}

				{meals.map(item =>
					<PassThrough key={`cat-${item.id}`}>

						{item.subcategory_name && item.subcategory_name !== subcategory &&
						<>
							<article
                                className="subcategory"
                            >
								<div className="content">
									<h3>{subcategory = item.subcategory_name}</h3>
								</div>
							</article>
						</>
						}

						<MenuItem
							item={item}
							table={table}
							currency={currency}
							fromCategory={selectedCategory.id}
							icon={selectedCategory.icon}
						/>

					</PassThrough>
				)}
			</div>

			<BackToMainMenuButton to={pathToMainMenu} label={t('buttons.backToMainMenu')}/>

			<CallWaiterButton/>

			<BottomBanner position="article_list" tags={selectedCategory.tags}/>

		</div>
	);
}

const stateToProps = (state, props) => {
	const {match} = props;
	const categoryId = parseInt(match.params.id);

	return {
		categoryId,
		selectedCategory: getCategory(state, categoryId),
		meals: getMenuItems(state, categoryId),
        currency: getCurrency(state),
        table: getTableCode(state),
		oldScrollTop: getMealsScrollTop(state, categoryId),
		isDeliveryEnabled: isDeliveryEnabled(state),
		restaurant: getRestaurant(state),
		deliveryPhone: getDeliveryPhone(state),
		isCartDisabled: isCartDisabled(state),
	}
};

export default connect(
	stateToProps,
	{
		updateMealsScrollTop,
	}
)(Meals);