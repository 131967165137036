import {createAction} from "@reduxjs/toolkit";
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import i18n from '../i18n';

export const displayThankYouMessage = createAction('ui/displayThankYouMessage');
export const displayBillPaymentOption = createAction('ui/displayBillPaymentOption');
export const displayWaiterOption = createAction('ui/displayWaiterOption');
export const displayCallWaiterOption = createAction('ui/displayCallWaiterOption');
export const displayCallWaiterWithoutLanguageOption = createAction('ui/displayCallWaiterWithoutLanguageOption');
export const changeLanguage = createAction('ui/changeLanguage');
export const showInfoMessage = createAction('ui/showInfoMessage');
export const showConfirmationPopup = createAction('ui/showConfirmationPopup');
export const hideConfirmationPopup = createAction('ui/hideConfirmationPopup');
export const setConfirmationPopupOrderId = createAction('ui/setConfirmationPopupOrderId');
export const resetConfirmationPopupOrderId = createAction('ui/resetConfirmationPopupOrderId');
export const updateCategoryScrollTop = createAction('ui/updateCategoryScrollTop');
export const updateMealsScrollTop = createAction('ui/updateMealsScrollTop');
export const showRequestConfirmationPopup = createAction('ui/showRequestConfirmationPopup');
export const hideRequestConfirmationPopup = createAction('ui/hideRequestConfirmationPopup');
export const showPlaceOrderConfirmationPopup = createAction('ui/showPlaceOrderConfirmationPopup');
export const hidePlaceOrderConfirmationPopup = createAction('ui/hidePlaceOrderConfirmationPopup');
export const showSurveyPopup = createAction('ui/showSurveyPopup');
export const hideSurveyPopup = createAction('ui/hideSurveyPopup');

export const requestChangeLanguage = (lang) => (dispatch) => {
    i18n.changeLanguage(lang);
    axios.defaults.headers.common['Accept-Language'] = lang;

    // Set html lang attribute
    document.documentElement.lang = lang.split('-')[0];

    // Add extra data to Sentry
    Sentry.setExtra('language', lang);

    dispatch(changeLanguage(lang));
};
