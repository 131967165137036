import {loadState, saveState} from "./localStorage";
import {configureStore as rstConfigureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import throttle from "lodash/throttle";

export const configureStore = () => {

    const persistedState = loadState();
    const store = rstConfigureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: persistedState,
    });

    /**
     * Save store state in localStorage.
     */
    store.subscribe(throttle(() => {
        const currentState = store.getState();

        saveState({
            ui: {
                languageSelector: currentState.ui.languageSelector,
                confirmationPopupVisible: currentState.ui.confirmationPopupVisible,
            },
            cart: currentState.cart,
            restaurant: {
                previousRestaurantId: currentState.restaurant.previousRestaurantId,
            },
            deliveryData: currentState.deliveryData,
        });
    }), 1000);

    return store;
};
