import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {generatePath, useLocation, withRouter} from "react-router-dom";
import ReactGA from 'react-ga';

import IconCheck from "./icons/IconCheck";

import {
    canUseDelivery,
    getCartItems,
    getCartTotal,
    getDeliveryData,
    getDeliveryPhone,
    getMenu,
    getMenuItem,
    getOrderIsSending,
    getRestaurantOrderTypes,
    getTableCode,
    isAcceptingSms,
    isDeliveryAvailable,
    isDeliveryEnabled,
} from "../reducers";
import {hidePlaceOrderConfirmationPopup,} from "../actions/ui";
import {clearCart, sendOrder, trackPurchase,} from "../actions/cart";
import {updateDeliveryData} from "../actions/deliveryData";

import BottomBanner from './BottomBanner';
import AddressForm from "./AddressForm";
import {formatCartForMessage, getPreparedItems} from "../utils";


function PopUpWrapper({children, isDeliveryEnabled, isDeliveryAvailable}) {
    let className = 'info-center';
    if (isDeliveryEnabled && isDeliveryAvailable) {
        className = 'order-confirmation';
    }
    return (
        <div className="app-inner not-found">
            <div className={className}>
                {children}
            </div>
            <BottomBanner/>
        </div>
    );
}
PopUpWrapper.propTypes = {
    isDeliveryEnabled: PropTypes.bool,
    isDeliveryAvailable: PropTypes.bool,
};

const PlaceOrderConfirmationPopup = (
    {
        table,
        history,
        hidePlaceOrderConfirmationPopup,
        cartItems,
        sendOrder,
        orderIsSending,
        isDeliveryAvailable,
        orderTypes,
        isAcceptingSms,
        deliveryPhone,
        menu,
        isDeliveryEnabled,
        clearCart,
        trackPurchase,
        cartTotal,
        updateDeliveryData,
        deliveryData,
        canUseDelivery,
    }) => {

    const [t] = useTranslation();

    // Track popup as modal pageview
    let location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search + '#orderConfirmation');
        return () => {
            ReactGA.pageview(location.pathname + location.search);
        };
    }, [location.pathname, location.search]);

    // State
	const [address, setAddress] = useState({});
	const [addressValid, setAddressValid] = useState(false);

	// SMS Message parts
    const header = 'Porudžbina sa klopa.net\n\n';
    const [deliveryInfo, setDeliveryInfo] = useState('');
    const [cartMessage, setCartMessage] = useState('');

    // Update message delivery info
    useEffect(() => {
        if (address) {
            let t = address.type === 'delivery' ? 'Dostava' : 'Preuzimanje u lokalu';
            t +=`
Ime: ${address.guest_name}
Telefon: ${address.guest_phone}
`;

            if (address.type === 'delivery') {
                t += `Opština: ${address.guest_municipality}
Adresa: ${address.guest_address}
`;
            }
            setDeliveryInfo(t);
        }
    }, [address]);

    // Update cart message
    useEffect(() => {
        if (cartItems && menu && cartItems.length && menu.length) {
            setCartMessage(formatCartForMessage(cartItems, menu));
        }
    }, [cartItems, menu]);

    const cartPath = generatePath('/:table/cart', {table: table});

    // Handlers
    const handleBackToCart = (e) => {
        e.preventDefault();
        hidePlaceOrderConfirmationPopup();

        history.push(cartPath);
    };

    const onSendOrder = (e) => {
		e.preventDefault();

		// Validate that address is valid
        if (isDeliveryEnabled && isDeliveryAvailable && !addressValid) {
            alert(t('messages.fillAddressFormAlert'));
            return;
        }

		const preparedItems = getPreparedItems(cartItems);

		sendOrder(table, preparedItems, address);
	};

    const onSendSms = e => {
        // Validate that address is valid
        if (!addressValid) {
            e.preventDefault();
            alert(t('messages.fillAddressFormAlert'));
            return;
        }

        if (isAcceptingSms) {
            console.log('sending sms...');
            updateDeliveryData(address);
            trackPurchase();
        } else {
            e.preventDefault();
        }
    };

    return (
        <PopUpWrapper isDeliveryAvailable={isDeliveryAvailable} isDeliveryEnabled={isDeliveryEnabled}>

            {isDeliveryEnabled && !isDeliveryAvailable &&
                <p>{t('messages.closedForDelivery')}</p>
            }

            {isDeliveryEnabled && isDeliveryAvailable &&
                <AddressForm
                    onChange={(address, valid) => {setAddress(address); setAddressValid(valid);}}
                    values={{
                        guest_name: deliveryData.guest_name,
                        guest_phone: deliveryData.guest_phone,
                        guest_address: deliveryData.guest_address,
                        guest_municipality: deliveryData.guest_municipality,
                        type: 'delivery',
                    }}
                    orderTypes={orderTypes}
                />
            }

            <div className="inline-button-holder frame">
                <a href="#cart" onClick={handleBackToCart} className="btn large inline flat">
                    <span className="btn-icon action-icon back"><span></span></span>
                    {t('buttons.backToCart')}
                </a>
            </div>

            {orderIsSending ?
                <a href="#order" className="btn large reduced-padding" onClick={(e) => {e.preventDefault();}}>
                    <IconCheck/>{t('messages.orderSending')}
                </a>
            :
                (isDeliveryEnabled ?
                    (isDeliveryAvailable &&
                        <>
                            {isAcceptingSms &&
                            <a
                                href={`sms:${deliveryPhone}?&body=${encodeURIComponent(`${header}${deliveryInfo}\n${cartMessage}`)}`}
                                className="btn large reduced-padding"
                                style={{marginBottom: '20px'}}
                                onClick={onSendSms}
                            >
                                <IconCheck/>{t('buttons.confirmOrder')}
                            </a>
                            }

                            {!isAcceptingSms && canUseDelivery &&
                            <a href="#order" className="btn large reduced-padding" onClick={onSendOrder}>
                                <IconCheck/>{t('buttons.confirmOrder')}
                            </a>
                            }
                        </>
                    )
                :
                    <a href="#order" className="btn large reduced-padding" onClick={onSendOrder}>
                        <IconCheck/>{t('buttons.confirmOrder')}
                    </a>
                )
            }

        </PopUpWrapper>
    );
};

PlaceOrderConfirmationPopup.propTypes = {
    table: PropTypes.string,
    history: PropTypes.object,
    hidePlaceOrderConfirmationPopup: PropTypes.func,
    cartItems: PropTypes.array,
    orderTypes: PropTypes.array,
    sendOrder: PropTypes.func,
    orderIsSending: PropTypes.bool,
    isDeliveryAvailable: PropTypes.bool,
    isAcceptingSms: PropTypes.bool,
    deliveryPhone: PropTypes.string,
    menu: PropTypes.array,
    isDeliveryEnabled: PropTypes.bool,
    clearCart: PropTypes.func,
    trackPurchase: PropTypes.func,
    cartTotal: PropTypes.number,
    updateDeliveryData: PropTypes.func,
    deliveryData: PropTypes.object,
    canUseDelivery: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const cartItems = getCartItems(state).map(item => ({
		...getMenuItem(state, item.id),
		...item
	}));

    return {
        table: getTableCode(state),
        orderIsSending: getOrderIsSending(state),
        isDeliveryAvailable: isDeliveryAvailable(state),
        cartItems,
        orderTypes: getRestaurantOrderTypes(state),
        isAcceptingSms: isAcceptingSms(state),
        deliveryPhone: getDeliveryPhone(state),
        menu: getMenu(state),
        isDeliveryEnabled: isDeliveryEnabled(state),
        cartTotal: getCartTotal(state),
        deliveryData: getDeliveryData(state),
        canUseDelivery: canUseDelivery(state),
    }
};

export default withRouter(connect(
    mapStateToProps,
    {
        hidePlaceOrderConfirmationPopup,
        sendOrder,
        clearCart,
        trackPurchase,
        updateDeliveryData,
    }
)(PlaceOrderConfirmationPopup));