import React, {useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import BackButton from './BackButton';
import LanguageSelector from './LanguageSelector';

import {sendRequest,} from "../actions/cart";
import {
	displayBillPaymentOption,
	displayCallWaiterOption,
	displayCallWaiterWithoutLanguageOption,
	displayThankYouMessage,
	displayWaiterOption
} from "../actions/ui";
import {
	canUsePaymentTypeCard,
	canUsePaymentTypeRoom,
	canUseRequestBill,
	canUseWaiterCall,
	getCurrentWorkingHours,
	getDeliveryPhone,
	getDeliveryPriceText,
	getRestaurant,
	getSideMenuState,
	getTableCode,
	getWaiterRequestIsSending,
	isDeliveryEnabled,
} from "../reducers";

import IconBell from './icons/IconBell';
import IconReceipt from './icons/IconReceipt';
import IconClose from './icons/IconClose';
import VersionDisplay from "./VersionDisplay";
import Spinner from "./Spinner";
import LinkButton from "./LinkButton";
import DeliveryInfo from "./DeliveryInfo";

import 'react-flags-select/scss/react-flags-select.scss';
import {BillPaymentSelector} from "./BillPaymentSelector";


function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const REQUEST_STATE_READY = 'ready';
const REQUEST_STATE_READY_NO_LANGUAGE = 'ready-no-language';
const REQUEST_STATE_BILL = 'bill';
const REQUEST_STATE_SENT = 'sent';
const REQUEST_STATE_WAITER = 'waiter';

function Header(
	{
		pageTitle = '',
		requestIsSending,
		match,
		table,
		restaurant,
		languages,
		state,
		backLabel,
		sendRequest,
		goBackTo,
		displayThankYouMessage,
		displayBillPaymentOption,
		displayWaiterOption,
		displayCallWaiterOption,
		isDeliveryEnabled,
		workingHours,
		deliveryPhone,
		canUseWaiterCall,
		canUseRequestBill,
		deliveryPrice,
		canUsePaymentTypeCard,
		canUsePaymentTypeRoom,
	}) {

	const [t] = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const [guestComment, setGuestComment] = useState('');

	const onHideMenu = useCallback(() => {
	    // Go back only if we opened popup from inside our app.
	    if (location.state && (location.state.fromCallWaiter || location.state.fromSideMenu)) {
	        history.goBack();
        } else {
	        history.replace({
				pathname: location.pathname,
				search: location.search,
				state: location.state,
				hash: '',
	        });
        }
    }, [history, location.state, location.pathname, location.search]);

	// Hide side menu when request is sent
	const prevRequestIsSending = usePrevious(requestIsSending);
	useEffect(() => {
		// Hide menu only on after request is stopped sending.
		if (!requestIsSending && prevRequestIsSending) {
			onHideMenu();
		}
	}, [requestIsSending, prevRequestIsSending, onHideMenu]);

	const onGuestCommentChange = (e) => {
		setGuestComment(e.target.value);
	};

	const onSendRequest = (requestType = 'call', paymentType = '', billForCompany = false, guestComment = '') => {
		sendRequest(table, requestType, paymentType, billForCompany, guestComment);
		setGuestComment('');
		displayThankYouMessage();
	};

	const onRequestBill = () => {
		displayBillPaymentOption();
	};

	const onCallWaiter = () => {
		displayWaiterOption();
	};

	const onOpenMenu = () => {
		displayCallWaiterOption();

        const newLocation = {...location, hash: '#side-menu', state: {fromSideMenu: true}};
        history.push(newLocation);
	};

	const menuActive = location.hash === '#side-menu' ? 'active' : '';
	const requestState = state;

	const currentPath = match.path;

	return (
		<header className={`main-header ${menuActive}`}>
			<div className="left-action">

			{!currentPath.includes('category') ?
				<BackButton to={goBackTo} label={backLabel}/>
			: null }
			</div>

			{!currentPath.includes('single') ?
				<h1>{pageTitle}</h1>
			: null }

			<div className="right-action">
				<div className="navbar-action" onClick={onOpenMenu}>
					<span></span>
				</div>
			</div>

			<div className="background-overlay"></div>

			<div className="side-menu">
				<LinkButton className="medium close-popup" onClick={onHideMenu}>
					<IconClose/>{t('buttons.close')}
				</LinkButton>

				<div className="side-menu-content">

					<div className={requestState === REQUEST_STATE_READY || requestState === REQUEST_STATE_READY_NO_LANGUAGE ? 'show' : 'hide'}>

						{restaurant &&
						<figure>
							{restaurant.logo ?
								<img src={restaurant.logo.small} alt="Logo" />
							:
								<h1>{restaurant.name}</h1>
							}
						</figure>
						}

						{requestState === REQUEST_STATE_READY &&
						<LanguageSelector countries={languages}/>
						}

						{isDeliveryEnabled &&
							<DeliveryInfo
								workingHours={workingHours}
								deliveryPhone={deliveryPhone}
								componentLocation="Side menu"
								restaurantName={restaurant.name}
								deliveryPrice={deliveryPrice}
							/>
						}

						{canUseRequestBill &&
							<LinkButton onClick={onRequestBill}><IconReceipt />{t('buttons.requestBill')}</LinkButton>
						}
						{canUseWaiterCall &&
							<>
								{/*<LinkButton onClick={onSendRequest}><IconBell />{t('buttons.callWaiter')}</LinkButton>*/}
								<LinkButton onClick={onCallWaiter}><IconBell />{t('buttons.waiterOption')}</LinkButton>
							</>
						}

					</div>

					{requestState === REQUEST_STATE_BILL &&
						<BillPaymentSelector
							onPaymentSelected={(paymentType, billForCompany) => onSendRequest('pay', paymentType, billForCompany)}
							canUsePaymentTypeCard={canUsePaymentTypeCard}
							canUsePaymentTypeRoom={canUsePaymentTypeRoom}
							canUseBillForCompany={true}
						/>
					}


					<div className={requestState === REQUEST_STATE_WAITER ? 'show' : 'hide'}>
						<ul className="form-fields mt-40">
							<li>
								<label>{t('messages.waiterMessage')}</label>
								<textarea
									id="guest-comment"
									name="guest-comment"
									placeholder={t('messages.waiterMessageExample')}
									rows={1} maxLength={140}
									value={guestComment}
									onChange={onGuestCommentChange}
								/>
							</li>
						</ul>
						<LinkButton
							onClick={() => onSendRequest('call', '', false, guestComment)}
							enabled={guestComment.trim() !== ''}
						><IconBell />{t('buttons.sendWaiterMessage')}</LinkButton>
						<p className="button-vertical-separator">{t('messages.waiterActionSeparators')}</p>
						<LinkButton
							onClick={() => onSendRequest('call')}
						><IconBell />{t('buttons.callWaiter')}</LinkButton>
					</div>

					<div className={requestState === REQUEST_STATE_SENT ? 'show' : 'hide'}>

						<Spinner/>

					</div>

				</div>

				<VersionDisplay/>

			</div>
		</header>
	);
}
Header.propTypes = {
	pageTitle: PropTypes.string,
	restaurant: PropTypes.object,
	languages: PropTypes.array,
	sendRequest: PropTypes.func,
	table: PropTypes.string,
	state: PropTypes.string,
	displayThankYouMessage: PropTypes.func,
	displayBillPaymentOption: PropTypes.func,
	displayWaiterOption: PropTypes.func,
	displayCallWaiterOption: PropTypes.func,
	displayCallWaiterWithoutLanguageOption: PropTypes.func,
	goBackTo: PropTypes.any,
	backLabel: PropTypes.string,
	requestIsSending: PropTypes.bool,
	isDeliveryEnabled: PropTypes.bool,
	workingHours: PropTypes.object,
	deliveryPhone: PropTypes.string,
	canUseWaiterCall: PropTypes.bool,
	canUseRequestBill: PropTypes.bool,
	deliveryPrice: PropTypes.string,
	canUsePaymentTypeCard: PropTypes.bool,
	canUsePaymentTypeRoom: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const restaurant = getRestaurant(state);
	const languages = restaurant ? restaurant.languages : [];
	return {
		restaurant: getRestaurant(state),
		table: getTableCode(state),
		languages,
		state: getSideMenuState(state),
		requestIsSending: getWaiterRequestIsSending(state),
		isDeliveryEnabled: isDeliveryEnabled(state),
		workingHours: getCurrentWorkingHours(state),
		deliveryPhone: getDeliveryPhone(state),
		canUseWaiterCall: canUseWaiterCall(state),
		canUseRequestBill: canUseRequestBill(state),
		deliveryPrice: getDeliveryPriceText(state),
		canUsePaymentTypeCard: canUsePaymentTypeCard(state),
		canUsePaymentTypeRoom: canUsePaymentTypeRoom(state),
	};
};

export default withRouter(connect(mapStateToProps, {
	sendRequest,
	displayBillPaymentOption,
	displayWaiterOption,
	displayThankYouMessage,
	displayCallWaiterOption,
	displayCallWaiterWithoutLanguageOption
})(Header));