import React from 'react';

export default function VersionDisplay() {
    return (
        <div className="version-display">
            <p>
                {`v${process.env.REACT_APP_VERSION}`}
            </p>
        </div>
    );
}
