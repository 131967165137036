import {createReducer} from "@reduxjs/toolkit";

import {
    stopTimer,
    timerTick,
} from "../actions/timer";

export default createReducer(
    0,
    {
        [timerTick]: (state) => {
            return state + 1;
        },
        [stopTimer]: () => {
            return 0;
        }
    }
);
