import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getInfoMessage} from "../reducers";
import {showInfoMessage} from "../actions/ui";


const MESSAGE_DURATION = 1500;

class InfoMessage extends React.Component {

    static propTypes = {
        message: PropTypes.string,
        showInfoMessage: PropTypes.func,
    };

    state = {
        showMessage: false,
    };

    messageTimeoutId = false;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {message, showInfoMessage} = this.props;

        if (message && (message !== prevProps.message)) {
            this.setState({showMessage: true});

            window.clearTimeout(this.messageTimeoutId);

            this.messageTimeoutId = window.setTimeout(() => {
                this.setState({showMessage: false});
                showInfoMessage(null);
            }, MESSAGE_DURATION);
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.messageTimeoutId);
    }

    render () {
        const {message} = this.props;
        const {showMessage} = this.state;

        if (!showMessage) {
            return null;
        }

        return (
            <div className="info-message">
                <div className="info-text">{message}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        message: getInfoMessage(state),
    }
};

export default connect(
    mapStateToProps,
    {
        showInfoMessage,
    }
)(InfoMessage);