import React from "react";
import * as PropTypes from "prop-types";

export function SurveyResults({surveyResult, selectedAnswer}) {
    const surveyTotal = surveyResult.answers.reduce((acc, item) => acc + item.total, 0);
    return (
        <ul className="radio-item-list">
            {surveyResult.answers.map(answer => {
                const perc = Math.round((answer.total / surveyTotal) * 100);
                return (
                    <li key={answer.id}>
                        <span className="radio-item">
                            <input
                                type="radio"
                                name={`survey-${answer.id}`}
                                id={`survey-${answer.id}`}
                                value=""
                                disabled
                                checked={parseInt(selectedAnswer) === answer.id}
                            />
                            <label htmlFor={`survey-${answer.id}`}><em>{answer.answer}</em>
                                <span className="result-number">{perc}%</span>
                                <span className="result-stripe" style={{width: perc.toString(10) + '%'}}></span>
                            </label>
                        </span>
                    </li>
                );
            })}
        </ul>
    );
}

SurveyResults.propTypes = {
    surveyResult: PropTypes.object,
    selectedAnswer: PropTypes.string,
};
