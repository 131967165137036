import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from 'classnames';

const BackToMainMenuButton = ({to, label, noBottomSpacing = false}) => {
    const classes = classnames(
        ['inline-button-holder', 'pull-top', 'frame'],
        {
            'space-bottom': !noBottomSpacing,
        }
    )
    return (
        <div className={classes}>
            <Link to={to} className="btn large inline flat">
                <span className="btn-icon action-icon back"><span></span></span>
                {label}
            </Link>
        </div>
    );
};

BackToMainMenuButton.propTypes = {
	to: PropTypes.any,
	label: PropTypes.any,
    noBottomSpacing: PropTypes.bool,
};

export default BackToMainMenuButton;