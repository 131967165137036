import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import './i18n';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {configureStore} from "./configureStore";

import './assets/css/main.css';

const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

// Initialize Sentry for staging and production
if (NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://f4182d0a7b0641deb9c339178f6226bd@sentry.io/1505794",
        environment: REACT_APP_ENVIRONMENT,
    });
}

// Initialize analytics
const gaId = process.env.REACT_APP_GA_ID;
if (gaId && gaId !== '') {

    ReactGA.initialize(gaId, {
        debug: (REACT_APP_ENVIRONMENT !== 'production'),
    });
    ReactGA.plugin.require('ecommerce');
}

//  Initialize redux store
const store = configureStore();

render((
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
), document.getElementById('root'));

// Enable hot reloading
if (NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept('./components/App', () => {
            const App = require('./components/App').default;
            render((
                <Provider store={store}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </Provider>
            ), document.getElementById('root'));
        })
    }
}


// Fix problem with vh unit on mobile browsers
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();