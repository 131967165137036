import { createReducer } from '@reduxjs/toolkit';

import {
    fetchMenuStarted,
    fetchMenuSucceeded,
    fetchMenuError
} from "../actions/menu";

export default createReducer(
    {
        loading: false,
        data: [],
        error: undefined,
    },
    {
        [fetchMenuStarted]: (state, action) => {
            state.loading = true;
            state.error = undefined;
        },
        [fetchMenuSucceeded]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [fetchMenuError]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
);
