import React, { useEffect } from 'react';
import {
	Switch,
	Route,
	withRouter,
	useLocation,
} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import {startTimer} from "../actions/timer";
import {clearPreviousDayOrders} from "../actions/cart";
import Main from './Main';


function usePageViews() {
	let location = useLocation();
	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search + location.hash);
	}, [location.pathname, location.search, location.hash]);
}

function App({clearPreviousDayOrders, startTimer}) {

	usePageViews();

	useEffect(() => {
		// startTimer();
		clearPreviousDayOrders();
	}, [clearPreviousDayOrders]);

	return (
		<div className="app-outer">
			<Switch>
				<Route path="/:table" component={Main}/>
			</Switch>
		</div>
	);
}
App.propTypes = {
	startTimer: PropTypes.func,
	clearPreviousDayOrders: PropTypes.func,
};

export default withRouter(connect(
	null,
	{
		startTimer,
		clearPreviousDayOrders
	})(App)
);
