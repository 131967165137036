import React, {useState} from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import InputNumber from 'rc-input-number';
import Thumbnail from './Thumbnail';
import Dotdotdot from "react-dotdotdot";

import IconTrash from "./icons/IconTrash";
import {modifierHasPrice} from "../utils";

function CartItem(
	{
		cartItem,
		currency,
		onUpdateQuantity,
		onDelete,
		editable=true,
	}
) {

	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const onDeleteClick = (e) => {
		e.preventDefault();
		onDelete();
	};

	const onChange = (value) => {
		onUpdateQuantity(value);
	};

	const {selectedModifiers = {}} = cartItem;

	const flatModifiers = cartItem.modifier_groups.reduce((acc, group) => {
		return group.modifiers.reduce((modAcc, modifier) => {
			if (selectedModifiers.hasOwnProperty(group.id)) {
				if (group.group_type === 'multi' && selectedModifiers[group.id][modifier.id]) {
					return {
						...modAcc,
						[modifier.id]: {name: modifier.name, price: modifier.price},
					};
				} else if (group.group_type === 'single' && selectedModifiers[group.id] === modifier.id) {
					return {
						...modAcc,
						[modifier.id]: {name: modifier.name, price: modifier.price},
					};
				}
			}
			return modAcc;
		}, acc);
	}, {});

	const modifierList = [];
	for (const modId in flatModifiers) {
		modifierList.push(flatModifiers[modId]);
	}

	return (
		<article className="open">
			<div className="inner-article">

				<Thumbnail
					thumbnail={cartItem.thumbnail}
					icon={cartItem.categoryIcon}
					name={cartItem.name}
					onClick={toggleOpen}/>

				<a href="#open" onClick={toggleOpen} className="content">
					<Dotdotdot clamp={2} tagName='h3'>
						{cartItem.name}
					</Dotdotdot>
					<h4 className="item-price">
						<span>{`${cartItem.quantity} x ${parseFloat(cartItem.price).toFixed(2)} ${currency}`}</span>
						<span>{`${(parseFloat(cartItem.price) * cartItem.quantity).toFixed(2)} ${currency}`}</span>
					</h4>
				</a>
				<div className={classNames({
					'right-options': true,
					'toggle': true,
					open: open
				})
				} onClick={toggleOpen}>
					<span className="arrow-down"></span>
				</div>
			</div>
			<div className={classNames({'cart-more': true, open: open})}>
				<div className="more-content">
					<div className="selected-items">
						{modifierList.map((mod, i) =>
							<h4 key={i}>
								{mod.name}
								{modifierHasPrice(mod) &&
									<span> - {mod.price}&nbsp;{currency}</span>
								}
							</h4>
						)}

						{/* This fixes rendering bug EG-6 */}
						{modifierList.length === 0 && !open &&
						<h4>empty</h4>
						}
					</div>
					{editable &&
					<ul className="form-fields">
						<li className="cols">
							<span className="sec">
								<a className="btn icon ghost" href="#delete" onClick={onDeleteClick}>
									<IconTrash/>
								</a>
							</span>
							<span className="prim">
								<InputNumber
									min={1}
									max={10}
									value={cartItem.quantity}
									onChange={onChange}
									focusOnUpDown={false}
									precision={0}
								/>
							</span>
						</li>
					</ul>
					}
				</div>
			</div>
		</article>
	);
}
CartItem.propTypes = {
	cartItem: PropTypes.object.isRequired,
	currency: PropTypes.string.isRequired,
	onUpdateQuantity: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	editable: PropTypes.bool,
};

export default CartItem;